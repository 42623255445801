/* eslint-disable no-unused-vars */
import { useEffect, useCallback, useState } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import { fetchLeadsWithPropertyCards, LeadsWithPropCardsApiResponse, DataItem } from '../../../../api/property-cards/fetchLeadsWithPropertyCards';

/**
* @typedef {Object} Filter
* @property {string} label - The label of the filter.
* @property {string} value - The value of the filter (typically a MongoDB ID).
* @property {Record<string, any>} [otherFields] - Other optional fields.
*/

/**
* @typedef {Object} QueryState
* @property {number} page - The current page number.
* @property {number} limit - Number of items per page.
* @property {Filter[]} communities - An array of community filter objects.
* @property {Filter[]} buildings - An array of building IDs (mongodb ids).
* @property {Filter[]} propertyTypes - An array of property types.
* @property {string} beds - the number of beds.
* @property {string} search - Search term, used to find by name, email, or phone number.
*/

/**
 * @typedef {Object} useLeadsWithPropCardsReturnType
 * @property {DataItem[] | undefined} data - The returned data from the query.
 * @property {boolean} isError - Whether the query is in an error state.
 * @property {unknown} error - The error object if the query is in an error state.
 * @property {boolean} isFetching - Whether the query is currently fetching (re-fetching or validating cache too).
 * @property {"error" | "idle" | "loading" | "success"} status - The status of the query.
 */

// Utility function to get the current time in milliseconds
const getCurrentTime = () => new Date().getTime();

/**
 * @typedef {Object} useLeadsWithPropCardsOptions
 * @property {number} timeBeforeRefetchOnFocus - The time before refetching the query on window focus, in milliseconds.
 * @property {number} prefetchCount - The number of pages to prefetch, by default 2.
 * @property {number} staleTime - Specifies how long will the queries be cached, in milliseconds.
 * @property {boolean} condition - Specifies the condition that determines whether the query will run or not.
 */

/**
 * @description Fetching leads with property cards
 * @param {Axios} axios
 * @param {QueryState} queryState 
 * @param {useLeadsWithPropCardsOptions} options 
 * @returns {useLeadsWithPropCardsReturnType} hookResult
 */
const useLeadsWithPropCards = (axios, queryState, options) => {
  const [lastFocusTime, setLastFocusTime] = useState(getCurrentTime());

  const queryClient = useQueryClient();

  const refetchOnWindowFocus = useCallback(() => {
    const now = getCurrentTime();
    const timeElapsed = now - lastFocusTime;

    const threshold = options?.timeBeforeRefetchOnFocus ?? 1000 * 60 * 30;

    if (timeElapsed > threshold) {
      queryClient.refetchQueries(['leadsWithPropCards', queryState]);
    }

    setLastFocusTime(now);
  }, [lastFocusTime, options.timeBeforeRefetchOnFocus, queryClient, queryState]);

  useEffect(() => {
    window.addEventListener('focus', refetchOnWindowFocus);

    return () => {
      window.removeEventListener('focus', refetchOnWindowFocus);
    };
  }, [refetchOnWindowFocus]);

  const { data, isError, error, isFetching, status } = useQuery(
    ['leadsWithPropCards', queryState],
    () => fetchLeadsWithPropertyCards(axios, {
      params: {
        ...queryState,
        propertyTypes: queryState?.propertyTypes?.map(prop => prop?.value),
        buildings: queryState?.buildings?.map(building => building?.value),
        communities: (queryState?.communities?.map(selected => selected?.value) ?? []).flat()?.map(community => typeof community === 'string' ? community : community?.id ?? community?._id) ?? [],
      }
    }), { 
      keepPreviousData: true,
      refetchOnWindowFocus: false, // set to false because it's managed manually
      staleTime: options?.staleTime ?? 1000 * 60 * 10, // 10 minutes by default
      enabled: options?.condition ?? true
    });

  // Prefetch the next two pages
  useEffect(() => {
    const preFetchPages = async () => {
      if (queryState.page) {
        for (let i = 1; i <= (options?.prefetchCount ?? 2); i++) {
          const nextPage = queryState.page + i;

          await queryClient.prefetchQuery(['leadsWithPropCards', { ...queryState, page: nextPage }], () => fetchLeadsWithPropertyCards(axios, {
            params: {
              ...queryState,
              propertyTypes: queryState?.propertyTypes?.map(prop => prop?.value),
              buildings: queryState?.buildings?.map(building => building?.value),
              communities: (queryState?.communities?.map(selected => selected?.value) ?? []).flat().map(community => community?.id ?? community?._id),
              page: nextPage,
            }
          }), { 
            refetchOnWindowFocus: false, 
            staleTime: options?.staleTime ?? 1000 * 60 * 10,
            enabled: options?.condition ?? true
           });
        }
      }
    }
    let timeout = null;
    if (queryState.page === 1) {
      timeout = setTimeout(preFetchPages, 5000);
    } else {
      preFetchPages();
    }

    return () => {
      clearTimeout(timeout);
    }
    
  }, [axios, options?.condition, options?.prefetchCount, options?.staleTime, queryClient, queryState, queryState.page]);

  return { data, isError, error, isFetching, status };
};

export default useLeadsWithPropCards;