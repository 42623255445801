import React, { Suspense, useState } from "react";
import {
  Button,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Modal,
  ModalBody,
  Row,
  UncontrolledDropdown,
} from "reactstrap";
import { Icon, UserAvatar } from "../../../../../components/Component";
import NewAgentAvatar from "../../../../../pages/users/components/NewAgentAvatar";
import { findUpper } from "../../../../../utils/Utils";
import { useQueryClient } from "react-query";
import LoaderModal from "../../../../../components/modals/LoaderModal";
import { useNavigate } from "react-router-dom";

const EditUserProfileImage = React.lazy(() => 
  import("../../../../../pages/components/popup/UserUpdationPopups").then(module => ({ default: module.EditUserProfileImage }))
);
const AssignAgent = React.lazy(() => 
  import("../../../../../pages/components/kanban/KanbanForms").then(module => ({ default: module.AssignAgent }))
);

/**
 * A component for rendering the header card of the user profile, containing the name, avatar, phone number and a menu for actions.
 *
 * @param {Object} props - The props for the component.
 * @param {User} props.user - The profile user.
 * @param {import("../../../../../api/agents/fetchAgentByUserId").AgentResponse} props.agent - The user information to fetch deals for.
 * @param {boolean} props.isAgent - Whether the user is an agent or not
 * @param {boolean} props.hasPendingOrOverdue - Whether the user has pending/overdue tasks or not
 * @returns {JSX.Element} - The rendered component.
 */
const ProfileHeaderCard = ({ user, agent, isAgent, hasPendingOrOverdue }) => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const [assignAgentModal, setAssignAgentModal] = useState(false);
  const toggleAssignAgentModal = () => {
    setAssignAgentModal(!assignAgentModal);
  };

  const [updateProfileImageModal, setUpdateProfileImageModal] = useState(false);
  const toggleUpdateProfileImageModal = () => {
    setUpdateProfileImageModal(!updateProfileImageModal);
  };

  return (
    <>
      <div className="user-card">
        {isAgent ? (
          <NewAgentAvatar agent={agent} size={"md"} />
        ) : (
          <UserAvatar
            text={findUpper(`${user?.first_name} ${user?.last_name ?? ""}`)}
          />
        )}
        <div className="user-info">
          {isAgent ? <span className="text-primary">Agent</span> : null}
          <span className="lead-text">{`${user?.first_name} ${
            user?.last_name ?? ""
          }`}</span>
          <div className="sub-text" style={{ maxWidth: "12ch" }}>
            {user?.phone ?? null}
          </div>
        </div>
        <div className="user-action">
          <UncontrolledDropdown>
            <DropdownToggle tag="a" className="btn btn-icon btn-trigger me-n2">
              <Icon name="more-v"></Icon>
            </DropdownToggle>
            <DropdownMenu>
              <ul className="link-list-opt no-bdr">
                {isAgent ? (
                  <li>
                    <DropdownItem
                      tag="a"
                      onClick={(ev) => {
                        ev.preventDefault();
                        toggleUpdateProfileImageModal();
                      }}
                    >
                      <Icon name="camera-fill"></Icon>
                      <span>Change Photo</span>
                    </DropdownItem>
                  </li>
                ) : null}
                {!isAgent ? (
                  <li>
                    <DropdownItem
                      tag="a"
                      className="cursor-pointer"
                      onClick={(ev) => {
                        ev.preventDefault();
                        toggleAssignAgentModal();
                      }}
                    >
                      <Icon name="user-add"></Icon>
                      <span>Assign Agent</span>
                    </DropdownItem>
                  </li>
                ) : null}
              </ul>
            </DropdownMenu>
          </UncontrolledDropdown>
        </div>
      </div>
      {assignAgentModal && <Suspense fallback={<LoaderModal />}>
        <Modal
          size="lg"
          isOpen={assignAgentModal}
          toggle={toggleAssignAgentModal}
        >
          {!hasPendingOrOverdue ? (
            <AssignAgent
              toggle={toggleAssignAgentModal}
              user={user}
              callback={() => queryClient.invalidateQueries()}
            />
          ) : (
            <ModalBody>
              <div className="p-2">
                <h5 className="title">Reassign Agent</h5>
                <div className="mt-4">
                  <Row className="gy-4">
                    <span>Complete the pending task first.</span>
                  </Row>
                  <div className="mt-2 d-flex justify-content-end">
                    <Button onClick={toggleAssignAgentModal}>OK</Button>
                  </div>
                </div>
              </div>
            </ModalBody>
          )}
        </Modal>
      </Suspense>}
      {updateProfileImageModal && <Suspense fallback={<LoaderModal />}>
        <Modal
          size="lg"
          isOpen={updateProfileImageModal}
          toggle={toggleUpdateProfileImageModal}
        >
          <EditUserProfileImage
            toggle={toggleUpdateProfileImageModal}
            user={user}
            callback={() => {
              setTimeout(() => navigate(0), 500);
            }}
          />
        </Modal>
      </Suspense>}
    </>
  );
};

export default ProfileHeaderCard;
