/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect, useCallback, useMemo } from "react";
import Axios from "axios";
import { toast } from "react-toastify";
import { useSelector, useDispatch } from "react-redux";
import {
    fetchFilteredCommunities,
    fetchPropertiesAdmin,
} from "../../redux/actions/property";
import Head from "../../layout/head/Head";
import Content from "../../layout/content/Content";
import { Button, Modal, ModalBody, Input } from "reactstrap";
import {
    Block,
    BlockHeadContent,
    BlockTitle,
    BlockBetween,
    BlockHead,
    DataTableHead,
    DataTableItem,
    DataTableRow,
    Icon,
    Row,
    Col,
    TooltipComponent,
    BlockDes,
    PaginationComponent,
} from "../../components/Component";
import {
    actionS3Objects,
    uploadToS3,
} from "../../utils/envConfig";
import { useLocation, useNavigate } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import ConfirmModal from "../../components/miscellaneous/ConfirmModal";
import { TagsInput } from "react-tag-input-component";
import { useRef } from "react";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import queryString from "query-string";
import LoadingComponent from "../components/loader/tableLoader";
import { debounce } from "lodash";

const CommunityList = () => {
    let navigate = useNavigate();

    const axios = useAxiosPrivate();

    const dispatch = useDispatch();
    const [open, setOpen] = useState(false);
    const [edit, setEdit] = useState(false);
    // const Community = useSelector((state) => state.property?.communities);
    const property = useSelector((state) => state.property?.propertiesAdmin);
    const { communities, communitiesCount, communitiesLoading } = useSelector((state) => ({
        communities: state.property?.communities.results,
        communitiesCount: state.property?.communities.found,
        communitiesLoading: state.property?.loading,

    }));

    // State Variables
    const [showModal, setShowModal] = useState(false);
    const [communityId, setCommunityId] = useState("");
    const [Communityname, setCommunityName] = useState("");
    const [slug, setSlug] = useState(null);
    const [communitySlug, setCommunitySlug] = useState(null);
    const [check, SetCheck] = useState([]);
    const [imageAlt, setImageAlt] = useState("");
    const [metaDescription, setMetaDescription] = useState("");
    const [metaKeywords, setMetaKeywords] = useState([]);
    const [community, setCommunity] = useState("");
    const [metaTitle, setMetaTitle] = useState("");
    const location = useLocation();
    const query = queryString.parse(location.search);
    const [searchText, setSearchText] = useState(query?.search ?? "");

    // pagination state
    const [page, setPage] = useState(query?.page ? Number(query?.page) : 1);
    const [perPage, setPerPage] = useState(
        query?.limit ? Number(query?.limit) : 20
    );
    const [refresh, setRefresh] = useState(false);

    useEffect(() => {
        if (communities) {
            setCommunitySlug(communities);
        }
    }, [communities]);

    const [avatar, setAvatar] = useState("");
    const [displayAvatar, setDisplayAvatar] = useState("");
    const [communityIconPath, setCommunityIconPath] = useState("");
    const [loading, setLoading] = useState(false);

    const user = useSelector((state) => state?.user?.loggedInUser);

    const { access_token } = user || "";
    const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${access_token}`,
    };
    const createdBy = user?.id;
    const firstName = user?.first_name;
    const lastName = user?.last_name;
    const createdName = `${firstName} ${lastName}`;

    const folderName = "Community";

    const handleCommunity = async (e) => {
        e.preventDefault();
        const { value } = e.target;
        const slug = value?.trim()?.toLowerCase()?.split(" ")?.join("-");
        setCommunity(value);
        setSlug(slug);
    };
    const [errorMessage, setErrorMessage] = useState("");
    const fileInputRef = useRef(null);

    const handleImage = async (e) => {
        if (e.target.files) {
            const file = e.target.files[0];
            const allowedTypes = [
                "image/jpeg",
                "image/png",
                "image/gif",
                "image/jpg",
                "image/svg",
                "image/webp",
            ];

            if (allowedTypes.includes(file.type)) {
                let url = URL.createObjectURL(file);
                setDisplayAvatar(url);
                setAvatar(file);
                setErrorMessage("");
            } else {
                setErrorMessage(
                    "Please upload a valid image file (JPEG, PNG, GIF, JPG, SVG, WEBP)"
                );
            }
        }
    };

    const handleRemove = (e) => {
        e.preventDefault();
        setDisplayAvatar("");
        setAvatar(null);
        setErrorMessage("");
        // Clear the value of the file input
        const fileInput = document.querySelector('input[name="image"]');
        if (fileInput) {
            fileInput.value = ""; // Reset the input field value
        }
    };
    const handleEdit = (e, item) => {
        e.preventDefault();
        setOpen(true);
        setEdit(true);
        setCommunityId(item.id);
        setCommunity(item.community);
        setImageAlt(item?.image_alt);
        setMetaTitle(item?.meta_title);
        setMetaDescription(item?.meta_description);
        const localMetaKeywords = item?.meta_keywords?.split(", ") || "";
        setMetaKeywords(localMetaKeywords);
        setSlug(item.slug);
        setDisplayAvatar(item.image);
        setCommunityIconPath(item.icon);
    };

    const handleUpload = async (file, fileName) => {
        const uploadData = JSON.stringify({
            action: `${actionS3Objects[1]}`,
            fileName: fileName,
        });
        // console.log(uploadData)
        const resp = await axios.post(`/upload`, uploadData, {
            headers,
        });
        const s3url = resp.data.signedRequest;
        if (s3url) {
            try {
                const myHeaders = new Headers({ "Content-Type": "image/*" });
                await Axios.put(s3url, file, { myHeaders });
            } catch (err) {
                // Handle Error Here
                toast.error("Server error. Try Again!");
            }
        }
    };

    const queryCommunities = (params, updateRoute = true) => {
        const communitiesParams = {
            limit: params?.perPage ?? query?.limit,
            page: params?.page ?? query?.page,
            search: params?.search ?? query?.search,
        };

        const queryParams = new URLSearchParams();

        Object.keys(communitiesParams).forEach((key) => {
            const value = communitiesParams[key];
            if (Array.isArray(value)) {
                value.forEach((subValue) => queryParams.append(key, subValue));
                // eslint-disable-next-line eqeqeq
            } else if (value != undefined && value !== "null") {
                queryParams.append(key, value);
            }
        });

        const queryString = queryParams.toString();

        if (updateRoute) {
            const navigateParams = {};

            if (location.search.length) {
                navigateParams.replace = true;
            }

            navigate(
                `${"/community-list"}?${queryString}`,
                navigateParams
            );
        }
        return queryString;
    }

    const handleSearchSubmit = (event) => {
        setSearchText(event.target.value);
        debounceSearchCommunities({ search: event.target.value });
    };

    // debounce update query
    const updateSearchQuery = useCallback(queryCommunities, [location.search.length, navigate, query?.limit, query?.page, query?.search]);

    const debounceSearchCommunities = useMemo(() => {
        return debounce(updateSearchQuery, 500);
    }, [updateSearchQuery]);
    // end debouncing

    // Input Validation
    const validateAdd = (community, slug, img) => {
        if (community.length < 2) {
            toast.error("Enter valid Place Name");
            return false;
        } else if (/^\d+$/.test(community)) {
            toast.error("Enter valid Place Name");
            return false;
        } else if (!img) {
            toast.error("Upload Icon for Place");
            return false;
        } else if (slug.length < 2) {
            toast.error("Enter valid Slug");
            return false;
        } else if (slug.length > 2) {
            if (slug?.indexOf(" ") >= 0) {
                toast.error("Slug has whitespace");
                return false;
            }
            if (slug !== slug?.toLowerCase()) {
                toast.error("Slug should be in lowercase");
                return false;
            }
            const exists = communitySlug?.community?.some(
                (obj) => obj?.slug === slug
            );
            if (exists) {
                toast.error("Slug already exists, Choose different Slug name");
                return false;
            }
            return true;
        } else {
            return true;
        }
    };
    const validateEdit = (community, slug) => {
        if (community.length < 2) {
            toast.error("Enter valid Place Name");
            return false;
        } else if (slug?.length < 2) {
            toast.error("Enter valid Slug");
            return false;
        } else if (slug?.length > 2) {
            if (slug?.indexOf(" ") >= 0) {
                toast.error("Slug has whitespace");
                return false;
            }
            if (slug !== slug?.toLowerCase()) {
                toast.error("Slug should be in lowercase");
                return false;
            }
            const exists = communitySlug?.community?.some(
                (obj) => obj?.slug === slug
            );
            if (exists) {
                toast.error("Slug already exists for different Place!");
                return false;
            }
            return true;
        } else {
            return true;
        }
    };
    // Add Community Info in DB
    const addCommunity = async (e) => {
        e.preventDefault();
        if (validateAdd(community, slug, avatar)) {
            let fileName = "";
            if (avatar.name) {
                fileName = `${folderName}/${uuidv4()}.${avatar.name
                    .split(".")
                    .pop()}`;
                handleUpload(avatar, fileName);
            }

            const localMetaKeywords = metaKeywords
                ? metaKeywords?.join(", ")
                : "";
            const formData = {
                community: community,
                slug: slug,
                icon: fileName,
                meta_title: metaTitle,
                image_alt: imageAlt,
                meta_description: metaDescription,
                meta_keywords: localMetaKeywords,
            };

            try {
                setLoading(true);
                const res = await axios
                    .post(`/community`, formData, { headers })
                    .then((community) => {
                        setLoading(false);
                        navigate(0);
                    })
                    .catch((err) => {
                        toast.error("Place already exists!");
                    });
            } catch (err) {
                // console.log(err)
                toast.error("Server error. Try Again !");
            }
        }
    };

    const editCommunity = async (e) => {
        e.preventDefault();

        if (validateEdit(community, slug)) {
            let fileName = communityIconPath ? communityIconPath : "";
            if (avatar && avatar.name) {
                fileName = `${folderName}/${uuidv4()}.${avatar.name
                    .split(".")
                    .pop()}`;
                fileName && (await uploadToS3(avatar, fileName, "image/*", axios));
            }
            const localMetaKeywords = metaKeywords
                ? metaKeywords?.join(", ")
                : "";
            const formData = {
                community,
                slug: slug,
                icon: fileName,
                image_alt: imageAlt,
                meta_title: metaTitle,
                meta_description: metaDescription,
                meta_keywords: localMetaKeywords,
            };

            try {
                setLoading(true);
                const res = await axios
                    .patch(`/community/${communityId}`, formData, { headers })
                    .then((community) => {
                        navigate(0);
                    })
                    .catch((err) => {
                        toast.error("Place not modified !");
                    });
            } catch (err) {
                // console.log(err)
                toast.error("Server error. Try Again !");
                setLoading(false);
            }
        }
    };
    const handleModal = (e, id, name) => {
        e.preventDefault();
        setCommunityId(id);
        setCommunityName(name);
        setShowModal(true);
    };

    const closeModal = () => {
        setShowModal(false);
        resetForm();
    };
    const resetForm = () => {
        setOpen(false);
        setEdit(false);
        setCommunityId(null);
        setCommunity(null);
        setSlug(null);
        setDisplayAvatar(null);
        setCommunityIconPath("");
    };
    useEffect(() => {
        dispatch(fetchFilteredCommunities(axios));
        dispatch(fetchPropertiesAdmin(axios));
    }, [dispatch]);

    useEffect(() => {
        const queryString = queryCommunities({
            perPage,
            page,
            search: query?.search ?? null,
        });

        dispatch(fetchFilteredCommunities(axios, `?${queryString}`));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [refresh, location.search, page, perPage])

    const submitDel = async (e) => {
        e.preventDefault();
        try {
            await axios
                .delete(`/Community/${communityId}`, { headers })
                .then((Community) => {
                    navigate(0);
                })
                .catch((err) => {
                    if (err.response.data.message === "LinkedtoListing") {
                        toast.error(
                            "Place cannot be removed as it is linked to a Property Listing"
                        );
                    } else {
                        toast.error("Place not removed !");
                    }
                });
        } catch (err) {
            // console.log(err)
            toast.error("Server error. Try Again !");
        }
        setShowModal(false);
    };

    useEffect(() => {
        if (communities && communities?.length > 0 && property?.length > 0) {
            let arr = communities.map((item, index) =>
                property.map((p) => p.communityId === item.id)
            );
            let empty = [];
            arr.forEach((element) => {
                empty.push(element.reduce((x, y) => x || y, false));
            });
            SetCheck(empty);
        }
    }, [communities, property]);

    return (
        <>
            <React.Fragment>
                <Head title="Places"></Head>
                <Content>
                    <BlockHead size="sm">
                        <BlockBetween>
                            <BlockHeadContent>
                                <BlockTitle>Places</BlockTitle>
                                <BlockDes className="text-soft">
                                    <p>Found {communitiesCount ?? 0} Place(s).</p>
                                </BlockDes>
                            </BlockHeadContent>
                            <BlockHeadContent>
                                <div className="toggle-wrap nk-block-tools-toggle">
                                    <div className="toggle-expand-content">
                                        <ul className="nk-block-tools g-3">
                                            <li className="nk-block-tools-opt">
                                                <Button
                                                    className="toggle btn-icon d-md-none"
                                                    color="primary"
                                                >
                                                    <Icon name="plus"></Icon>
                                                </Button>
                                                <Button
                                                    className="toggle d-none d-md-inline-flex"
                                                    color="primary"
                                                    onClick={() => {
                                                        setOpen(true);
                                                    }}
                                                >
                                                    <Icon name="plus"></Icon>
                                                    <span>Add Place</span>
                                                </Button>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </BlockHeadContent>
                        </BlockBetween>
                    </BlockHead>
                    <div className="my-3 d-flex flex-row-reverse align-items-center justify-content-between">
                        {/* Entries per page config */}
                        <div className="d-flex align-items-center">
                            <span className="d-none d-sm-inline-block me-2">Show</span>
                            <div className="form-control-select">
                                <select
                                    name="DataTables_Table_0_length"
                                    className="custom-select custom-select-sm form-control form-control-sm"
                                    onChange={(e) => setPerPage(e.target.value)}
                                    value={perPage}
                                >
                                    <option value="1">1</option>
                                    <option value="5">5</option>
                                    <option value="10">10</option>
                                    <option value="20">20</option>
                                    <option value="25">25</option>
                                    <option value="50">50</option>
                                </select>
                            </div>
                        </div>
                        {/* Search bar */}
                        <div className="d-flex justify-content-center align-items-center">
                            <Input placeholder="Search Places..." value={searchText} type="text" name="search" onChange={handleSearchSubmit} style={{ minWidth: "20rem" }} />
                        </div>
                    </div>

                    <Block>
                        {!communitiesLoading && communities ? (
                            <div className="nk-tb-list is-separate is-medium mb-3">
                                <DataTableHead className="nk-tb-item">
                                    {/* <DataTableRow className="nk-tb-col-check">
                                        <div className="custom-control custom-control-sm custom-checkbox notext">
                                            <input
                                                type="checkbox"
                                                className="custom-control-input"
                                                id="pid-all"
                                                // onChange={ ( e ) => selectorCheck( e ) }
                                            />
                                            <label
                                                className="custom-control-label"
                                                htmlFor="pid-all"
                                            ></label>
                                        </div>
                                    </DataTableRow> */}
                                    <DataTableRow>
                                        <span className="sub-text">
                                            Place Name
                                        </span>
                                    </DataTableRow>
                                    <DataTableRow>
                                        <span className="sub-text">
                                            URL slug
                                        </span>
                                    </DataTableRow>
                                    <DataTableRow>
                                        <span className="sub-text">Edit</span>
                                    </DataTableRow>
                                    <DataTableRow>
                                        <span className="sub-text">Delete</span>
                                    </DataTableRow>
                                </DataTableHead>
                                {communities && communities.length ?
                                    [...communities].map((item, index) => (
                                        <DataTableItem key={index}>
                                            <DataTableRow>
                                                <a
                                                    href="#id"
                                                    onClick={(ev) =>
                                                        ev.preventDefault()
                                                    }
                                                >
                                                    {item?.community}
                                                </a>
                                            </DataTableRow>
                                            <DataTableRow>
                                                <a
                                                    href="#id"
                                                    onClick={(ev) =>
                                                        ev.preventDefault()
                                                    }
                                                >
                                                    {item?.slug}
                                                </a>
                                            </DataTableRow>
                                            <DataTableRow>
                                                <a
                                                    href="#"
                                                    onClick={(ev) =>
                                                        handleEdit(ev, item)
                                                    }
                                                >
                                                    <Icon
                                                        name="edit"
                                                        style={{
                                                            fontSize: "1.3rem",
                                                        }}
                                                    ></Icon>
                                                </a>
                                            </DataTableRow>
                                            <DataTableRow>
                                                {check.length > 0 &&
                                                    check[index] === true ? (
                                                    <>
                                                        <TooltipComponent
                                                            tag="a"
                                                            containerClassName="btn btn-icon"
                                                            id={"delete"}
                                                            icon="trash"
                                                            direction="top"
                                                            text={
                                                                "Place can't be removed as it is linked to a Property Listing"
                                                            }
                                                        />
                                                    </>
                                                ) : (
                                                    <a
                                                        onClick={(e) =>
                                                            handleModal(
                                                                e,
                                                                item.id,
                                                                item.community
                                                            )
                                                        }
                                                        className="btn btn-icon"
                                                    >
                                                        <Icon
                                                            name="trash"
                                                            style={{
                                                                color: "#e85347",
                                                                fontSize:
                                                                    "1.3rem",
                                                            }}
                                                        />
                                                    </a>
                                                )}
                                            </DataTableRow>
                                        </DataTableItem>
                                    )) : null}
                            </div>
                        ) : <LoadingComponent />}
                        {!communitiesLoading ? <PaginationComponent
                            noDown
                            currentPage={page ?? 1}
                            paginate={(page) => {
                                setRefresh(!refresh);
                                setPage(page);
                                queryCommunities({ perPage, page: page }, true);
                            }}
                            itemPerPage={perPage ?? 10}
                            totalItems={communitiesCount}
                        /> : null}
                    </Block>
                    {/* ****** Modal Start ****** */}
                    {showModal ? (
                        <ConfirmModal
                            msg={"Delete "}
                            name={"Community: " + Communityname}
                            open={showModal}
                            handleClick={handleModal}
                            handleConfirm={submitDel}
                            handlecloseModal={closeModal}
                            action="Delete"
                        />
                    ) : null}
                    {/* ****** Modal End ****** */}
                    <Modal
                        isOpen={open}
                        toggle={() => setOpen(false)}
                        className="modal-dialog-centered"
                        size="lg"
                    >
                        <ModalBody>
                            <a href="#cancel" className="close">
                                {" "}
                                <Icon
                                    name="cross-sm"
                                    onClick={(ev) => {
                                        ev.preventDefault();
                                        resetForm();
                                    }}
                                ></Icon>
                            </a>
                            <div className="p-2">
                                <h5 className="title">
                                    {edit ? "Edit" : "Add Place"}
                                </h5>
                                <div className="mt-4">
                                    <form
                                        onSubmit={(e) =>
                                            edit
                                                ? editCommunity(e)
                                                : addCommunity(e)
                                        }
                                    >
                                        <Row className="g-3">
                                            <Col md="6">
                                                <div className="form-group">
                                                    <label
                                                        className="form-label"
                                                        htmlFor="customer"
                                                    >
                                                        Place Name
                                                    </label>
                                                    <div className="form-control-wrap">
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            name="community"
                                                            value={community}
                                                            onChange={(e) =>
                                                                handleCommunity(
                                                                    e
                                                                )
                                                            }
                                                        />
                                                    </div>
                                                </div>
                                            </Col>
                                            {/* <Col size="6">
                                                <div className="form-group">
                                                    <label
                                                        className="form-label"
                                                        htmlFor="customer"
                                                    >
                                                        URL | Slug
                                                    </label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        name="community"
                                                        value={slug}
                                                        onChange={(e) =>
                                                            setSlug(
                                                                e.target.value
                                                            )
                                                        }
                                                    />
                                                </div>
                                            </Col> */}
                                            <Col size="6">
                                                <div className="form-group">
                                                    <label
                                                        className="form-label"
                                                        htmlFor="customer"
                                                    >
                                                        Image Alt
                                                    </label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        name="community"
                                                        value={imageAlt}
                                                        onChange={(e) =>
                                                            setImageAlt(
                                                                e.target.value
                                                            )
                                                        }
                                                    />
                                                </div>
                                            </Col>
                                            <Col size="6">
                                                <div className="form-group">
                                                    <label
                                                        className="form-label"
                                                        htmlFor="metaTitle"
                                                    >
                                                        Meta Title
                                                    </label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        name="metaTitle"
                                                        value={metaTitle}
                                                        onChange={(e) =>
                                                            setMetaTitle(
                                                                e.target.value
                                                            )
                                                        }
                                                    />
                                                </div>
                                            </Col>
                                            <Col size="6">
                                                <div className="form-group">
                                                    <label
                                                        className="form-label"
                                                        htmlFor="customer"
                                                    >
                                                        Meta Keywords
                                                    </label>
                                                    <TagsInput
                                                        value={metaKeywords}
                                                        onChange={(
                                                            metaKeywords
                                                        ) =>
                                                            setMetaKeywords(
                                                                metaKeywords
                                                            )
                                                        }
                                                        name="Tags"
                                                        placeHolder="Enter Meta Keywords"
                                                    />
                                                </div>
                                            </Col>
                                            <Col size="6">
                                                <div className="form-group">
                                                    <label
                                                        className="form-label"
                                                        htmlFor="customer"
                                                    >
                                                        Meta Description
                                                    </label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        name="community"
                                                        value={metaDescription}
                                                        onChange={(e) =>
                                                            setMetaDescription(
                                                                e.target.value
                                                            )
                                                        }
                                                    />
                                                </div>
                                            </Col>
                                            <Col sm="6">
                                                <div className="form-group">
                                                    <label className="form-label">
                                                        Icon
                                                    </label>
                                                    <div className="form-control-wrap">
                                                        <div className="form-file">
                                                            <Input
                                                                type="file"
                                                                id="customFile"
                                                                accept="image/*"
                                                                name="image"
                                                                onChange={(e) =>
                                                                    handleImage(
                                                                        e
                                                                    )
                                                                }
                                                                ref={
                                                                    fileInputRef
                                                                }
                                                            />
                                                        </div>
                                                        {errorMessage && (
                                                            <div className="text-danger">
                                                                {errorMessage}
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                                <Col sm="6">
                                                    {displayAvatar && (
                                                        <div className="position-relative">
                                                            <img
                                                                src={
                                                                    displayAvatar
                                                                }
                                                                alt="Avatar"
                                                            />
                                                            <button
                                                                className="btn btn-danger position-absolute top-0 start-0"
                                                                onClick={
                                                                    handleRemove
                                                                }
                                                            >
                                                                <Icon name="trash" />
                                                            </button>
                                                        </div>
                                                    )}
                                                </Col>
                                            </Col>

                                            <Col md="6">
                                                <div className="form-group">
                                                    <label
                                                        className="form-label"
                                                        htmlFor="customer"
                                                    >
                                                        Created By
                                                    </label>
                                                    <div className="form-control-wrap">
                                                        {createdName}
                                                    </div>
                                                </div>
                                            </Col>
                                            <Col size="12" className="d-flex justify-content-end">
                                                <Button
                                                    color="primary"
                                                    type="submit"
                                                >
                                                    {/* <Icon className="plus"></Icon> */}
                                                    <span>
                                                        {loading
                                                            ? "Loading.."
                                                            : edit
                                                                ? "Save"
                                                                : "Add Place"}
                                                    </span>
                                                </Button>
                                            </Col>
                                        </Row>
                                    </form>
                                </div>
                            </div>
                        </ModalBody>
                    </Modal>
                </Content>
            </React.Fragment>
        </>
    );
};

export default CommunityList;
