import { Button, Card, CardImg, Col, Input, Label, Row } from "reactstrap";
import { Block, Icon } from "../../../components/Component";
import Head from "../../../layout/head/Head";
import React, { useEffect, useState } from "react";
import Dropzone from "react-dropzone";
import { LazyLoadComponent } from "react-lazy-load-image-component";
import { useForm } from "react-hook-form";
import uuid from "react-uuid";
import useDragAndDrop from "../../../utils/hooks/useDragAndDrop";
import InputFile from "./InputFile";
import { deleteFromS3, deleteManyFromS3, uploadManyToS3 } from "../../../utils/envConfig";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";

const AddOffPlanStepFiveEdit = (props) => {
  const navigate = useNavigate();
  const axios = useAxiosPrivate();
  let {
    development,
    setDevelopment,
    finalePlanDetails,
    setFinalPlanDetails,
    filesToUpload,
    offPlanUpdate,
    setFilesToUpload,
    headerVideoFilesDev,
    headerFilesDev,
    brochureFilesDev,
    priceFilesDev,
    edit = false,
  } = props;

  const [toBeUpdated, setToBeUpdated] = useState({});
  const [disabled, setDisabled] = useState(false);
  const [toBeDeleted, setToBeDeleted] = useState({
    amenities: [],
    developmentPhotos: [],
    others: [],
    developmentLogo: "",
  });
  useEffect(() => {
    setHeaderFiles(headerFilesDev);
  }, [headerFilesDev]);

  useEffect(() => {
    setHeaderVideoFiles(headerVideoFilesDev);
  }, [headerVideoFilesDev]);

  useEffect(() => {
    setPriceFiles(priceFilesDev);
  }, [priceFilesDev]);

  useEffect(() => {
    setBrochureFiles(brochureFilesDev);
  }, [brochureFilesDev]);

  const [submitDisabled, setSubmitDisabled] = useState(false);
  const {
    active: activeHeaderVideo,
    errorMessage: headerVideoStatusMessage,
    dragenter: headerVideoDragEnter,
    dragleave: headerVidoDragLeave,
    drop: headerDropVideo,
    handleFileChange: headerVideoChange,
    files: headerVideoFiles,
    setFiles: setHeaderVideoFiles,
    removeImage: removerHeaderVideo,
  } = useDragAndDrop({
    multiple: false,
    inputType: "video",
  });

  const {
    active: activeHeader,
    errorMessage: headerStatusMessage,
    dragenter: headerDragEnter,
    dragleave: headerDragLeave,
    drop: headerDrop,
    handleFileChange: headerImageChange,
    files: headerFiles,
    setFiles: setHeaderFiles,
    removeImage: removerHeaderImage,
  } = useDragAndDrop({
    multiple: false,
    inputType: "image",
  });
  const {
    active: brochureActive,
    errorMessage: brochureStatus,
    dragenter: brochureDragEnter,
    dragleave: brochureDragLeave,
    drop: brochureDrop,
    handleFileChange: brochureFileChange,
    files: brochureFiles,
    setFiles: setBrochureFiles,
    removeImage: removeBrochureImage,
  } = useDragAndDrop({
    inputType: "pdf",
  });

  const {
    active: priceActive,
    errorMessage: priceStatus,
    dragenter: priceDragEnter,
    dragleave: priceDragLeave,
    drop: priceDrop,
    handleFileChange: priceFileChange,
    files: priceFiles,
    setFiles: setPriceFiles,
    removeImage: removePriceImage,
  } = useDragAndDrop({
    inputType: "image",
  });


  const saveAndExit = async () => {
    try {
      setDisabled(true);
      const video = headerVideoFiles[0];
      const headerImage = headerFiles[0];
      // const topViewImg = topViewFiles[0];
      // const mapImg = mapFiles[0];
      const priceImg = priceFiles[0];
      const brochure = brochureFiles[0];

      if (
        !headerImage?.file ||
        !priceImg?.file ||
        !brochure?.file
      )
        return alert("Please add all required images");

      const filesToDelete = [];
      const filesToUpload = [];

      const planDetails = {};

      if (video?.file && typeof video?.file !== "string") {
        const prevName = video?.prevFileName?.split(".")[0];

        const videoExt = video?.file?.name?.split(".")?.pop();

        const fileName = prevName
          ? `${prevName}.${videoExt}`
          : `offPlans/videos/${uuid()}.${videoExt}`;

        filesToDelete.push(video?.prevFileName);

        filesToUpload.push({
          file: video?.file,
          fileName,
          type: "video/*",
        });

        planDetails.video = fileName;
      }

      if (typeof headerImage?.file !== "string") {
        const fileName = `offPlans/otherImages/${uuid()}.${headerImage?.file?.name
          ?.split(".")
          ?.pop()}`;

        filesToDelete.push(headerImage?.prevFileName);

        filesToUpload.push({
          file: headerImage?.file,
          fileName,
          type: "image/*",
        });

        planDetails.headerImage = fileName;
      }
      if (typeof priceImg?.file !== "string") {
        const fileName = `offPlans/otherImages/${uuid()}.${priceImg?.file?.name
          ?.split(".")
          ?.pop()}`;

        filesToDelete.push(priceImg?.prevFileName);

        filesToUpload.push({
          file: priceImg?.file,
          fileName,
          type: "image/*",
        });

        planDetails.priceImage = fileName;
      }

      if (typeof brochure?.file !== "string") {
        const fileName = `offPlans/otherImages/${uuid()}.${brochure?.file?.name
          ?.split(".")
          ?.pop()}`;

        filesToDelete.push(brochure?.prevFileName);

        filesToUpload.push({
          file: brochure?.file,
          fileName,
          type: "application/pdf",
        });

        planDetails.brochure = fileName;
      }

      filesToUpload.length && (await uploadManyToS3(filesToUpload, axios));
      // console.log(filesToDelete)
      await offPlanUpdate({
          ...planDetails
    });

    // navigate(0)
    if (filesToDelete.length) {
      await Promise.all(
          filesToDelete.map(
              async (item) =>
                  await deleteFromS3(item,axios)
          )
      );
  }
    } catch (err) {
      toast.error(err?.message);
      // console.log(err);
    } finally {
      setDisabled(false);
    }
  };

  const { errors, register, handleSubmit } = useForm();

  const [uploadedVideo, setUploadedVideo] = useState([]);
  const handleDropVideo = (acceptedFiles) => {
    setUploadedVideo([...uploadedVideo, ...acceptedFiles]);
  };
  const handleDeleteVideo = (file) => {
    const updatedVideo = uploadedVideo.filter(
      (uploadedFile) => uploadedFile !== file
    );
    setUploadedVideo(updatedVideo);
  };
  ////

  const [uploadedImages, setUploadedImages] = useState([]);
  const handleDrop = (acceptedFiles) => {
    setUploadedImages([...uploadedImages, ...acceptedFiles]);
  };
  const handleDelete = (file) => {
    const updatedImages = uploadedImages.filter(
      (uploadedFile) => uploadedFile !== file
    );
    setUploadedImages(updatedImages);
  };
  ////
  const [uploadedPriceImages, setUploadedPriceImages] = useState([]);
  const handleDropPrice = (acceptedFiles) => {
    setUploadedPriceImages([...uploadedPriceImages, ...acceptedFiles]);
  };
  const handleDeletePrice = (file) => {
    const updatedImages = uploadedPriceImages.filter(
      (uploadedFile) => uploadedFile !== file
    );
    setUploadedPriceImages(updatedImages);
  };
  //////
  const [uploadedBrouchure, setUploadedBrouchure] = useState([]);
  const handleDropBrouchure = (acceptedFiles) => {
    setUploadedBrouchure([...uploadedBrouchure, ...acceptedFiles]);
  };
  const handleDeleteBrouchure = (file) => {
    const updatedImages = uploadedBrouchure.filter(
      (uploadedFile) => uploadedFile !== file
    );
    setUploadedBrouchure(updatedImages);
  };
  const submitForm = async (data) => {
    await saveAndExit();
  };
  //////
  return (
    <React.Fragment>
      <Head title="Off Plan"></Head>
      <Block>
        <form onSubmit={handleSubmit(submitForm)}>
          <Row className="gy-4">
            <Col sm="12">
              <Label>Header Video *</Label>
              <div>
                <InputFile
                  active={activeHeaderVideo}
                  dragenter={headerVideoDragEnter}
                  dragleave={headerVidoDragLeave}
                  drop={headerDropVideo}
                  errorMessage={headerVideoStatusMessage}
                  files={headerVideoFiles}
                  handleFileChange={headerVideoChange}
                  inputType="video"
                  removeImage={removerHeaderVideo}
                  inputProps={{ accept: "video/*" }}
                />
                {/* <Dropzone onDrop={ handleDropVideo }>
                                    { ( { getRootProps, getInputProps } ) => (
                                        <section>
                                            <div
                                                { ...getRootProps() }
                                                className="dropzone upload-zone small bg-lighter my-2 dz-clickable"
                                            >
                                                <Input { ...getInputProps( { accept: "video/mp4" } ) } />
                                                <div className="dz-message">
                                                    <span className="dz-message-text">
                                                        <span>Drag and drop</span> video here or <span>browse</span>
                                                    </span>
                                                </div>
                                            </div>
                                        </section>
                                    ) }
                                </Dropzone> */}
                {headerVideoFiles.length > 0 && (
                  <section>
                    <Label className="mt-2 mb-2">Uploaded Video</Label>
                    <div className="image-list">
                      <LazyLoadComponent key={headerVideoFiles[0]?.file?.name}>
                        <Card
                          className="position-relative"
                          style={{ width: "15rem" }}
                        >
                          <video
                            src={
                              !headerVideoFiles[0]?.file
                                ? null
                                : typeof headerVideoFiles[0]?.file === "string"
                                ? headerVideoFiles[0]?.file
                                : URL.createObjectURL(headerVideoFiles[0]?.file)
                            }
                            alt={headerVideoFiles[0]?.file?.name}
                          />
                          <Button
                            className="position-absolute"
                            style={{ top: "0", left: "0" }}
                            color="danger"
                            onClick={() => removerHeaderVideo(0)}
                          >
                            <Icon name="trash"> </Icon>
                          </Button>
                        </Card>
                      </LazyLoadComponent>
                    </div>
                  </section>
                )}
              </div>
            </Col>
            <Col sm="12">
              <Label>Header Image *</Label>
              <div>
                <InputFile
                  active={activeHeader}
                  dragenter={headerDragEnter}
                  dragleave={headerDragLeave}
                  drop={headerDrop}
                  errorMessage={headerStatusMessage}
                  files={headerFiles}
                  handleFileChange={headerImageChange}
                  inputProps={{ accept: "image/*" }}
                  inputType="image"
                  removeImage={removerHeaderImage}
                />
                {/* <Dropzone onDrop={ handleDrop }>
                                    { ( { getRootProps, getInputProps } ) => (
                                        <section>
                                            <div
                                                { ...getRootProps() }
                                                className="dropzone upload-zone small bg-lighter my-2 dz-clickable"
                                            >
                                                <input { ...getInputProps( { accept: 'image/*' } ) } />
                                                <div className="dz-message">
                                                    <span className="dz-message-text">
                                                        <span>Drag and drop</span> image here or <span>browse</span>
                                                    </span>
                                                </div>
                                            </div>
                                        </section>
                                    ) }
                                </Dropzone> */}
                {headerFiles?.length > 0 && (
                  <section>
                    <Label className="mt-2 mb-2">Uploaded Image</Label>
                    <div className="image-list">
                      <LazyLoadComponent key={headerFiles[0]?.file?.name}>
                        <Card
                          className="position-relative"
                          style={{ width: "15rem" }}
                        >
                          <CardImg
                            src={
                              !headerFiles[0]?.file
                                ? null
                                : typeof headerFiles[0]?.file === "string"
                                ? headerFiles[0]?.file
                                : URL.createObjectURL(headerFiles[0]?.file)
                            }
                            alt={headerFiles[0]?.file?.name ?? ""}
                          />

                          <Button
                            className="position-absolute"
                            style={{ top: "0", left: "0" }}
                            color="danger"
                            onClick={() => removerHeaderImage(0)}
                          >
                            <Icon name="trash"> </Icon>
                          </Button>
                        </Card>
                      </LazyLoadComponent>
                    </div>
                  </section>
                )}
              </div>
            </Col>
            <Col sm="12">
              <Label>Price Image *</Label>
              <div>
                {/* <Dropzone onDrop={ handleDropPrice }>
                                    { ( { getRootProps, getInputProps } ) => (
                                        <section>
                                            <div
                                                { ...getRootProps() }
                                                className="dropzone upload-zone small bg-lighter my-2 dz-clickable"
                                            >
                                                <input { ...getInputProps( { accept: 'image/*' } ) } />
                                                <div className="dz-message">
                                                    <span className="dz-message-text">
                                                        <span>Drag and drop</span> image here or <span>browse</span>
                                                    </span>
                                                </div>
                                            </div>
                                        </section>
                                    ) }
                                </Dropzone> */}
                <InputFile
                  active={priceActive}
                  dragenter={priceDragEnter}
                  dragleave={priceDragLeave}
                  drop={priceDrop}
                  errorMessage={priceStatus}
                  files={priceFiles}
                  handleFileChange={priceFileChange}
                  inputProps={{ accept: "image/*" }}
                  inputType="image"
                  removeImage={removePriceImage}
                />

                {priceFiles.length > 0 && (
                  <section>
                    <Label className="mt-2 mb-2">Uploaded Image</Label>
                    <div className="image-list">
                      <LazyLoadComponent key={priceFiles[0]?.file?.name}>
                        <Card
                          className="position-relative"
                          style={{ width: "15rem" }}
                        >
                          <CardImg
                            src={
                              !priceFiles[0]?.file
                                ? null
                                : typeof priceFiles[0]?.file === "string"
                                ? priceFiles[0]?.file
                                : URL.createObjectURL(priceFiles[0]?.file)
                            }
                            alt={priceFiles[0]?.file?.name}
                          />

                          <Button
                            className="position-absolute"
                            style={{ top: "0", left: "0" }}
                            color="danger"
                            onClick={() => removePriceImage(0)}
                          >
                            <Icon name="trash"> </Icon>
                          </Button>
                        </Card>
                      </LazyLoadComponent>
                    </div>
                  </section>
                )}
              </div>
            </Col>
            <Col sm="12">
              <Label>Development Brochure</Label>
              <div>
                {/* <Dropzone onDrop={ handleDropBrouchure }>
                                    { ( { getRootProps, getInputProps } ) => (
                                        <section>
                                            <div
                                                { ...getRootProps() }
                                                className="dropzone upload-zone small bg-lighter my-2 dz-clickable"
                                            >
                                                <input { ...getInputProps( { accept: 'file/*' } ) } />
                                                <div className="dz-message">
                                                    <span className="dz-message-text">
                                                        <span>Drag and drop</span> image here or <span>browse</span>
                                                    </span>
                                                </div>
                                            </div>
                                        </section>
                                    ) }
                                </Dropzone> */}
                <InputFile
                  active={brochureActive}
                  dragenter={brochureDragEnter}
                  dragleave={brochureDragLeave}
                  drop={brochureDrop}
                  errorMessage={brochureStatus}
                  files={brochureFiles}
                  handleFileChange={brochureFileChange}
                  inputType="pdf"
                  removeImage={removeBrochureImage}
                  inputProps={{ accept: "application/pdf" }}
                />
                {brochureFiles?.length > 0 && (
                  <section>
                    <Label className="mt-2 mb-2">Uploaded Brochure</Label>
                    <div className="image-list">
                      <LazyLoadComponent key={brochureFiles[0]?.file?.name}>
                        <Card
                          className="position-relative"
                          style={{ width: "15rem" }}
                        >
                          {typeof brochureFiles[0]?.file === 'string'? 
                          <a href={brochureFiles[0]?.file} download>
                      <i className="fas fa-download"></i>
                            <CardImg
                             src={ brochureFiles[0]?.file }
                                // Other props for CardImg
                                alt={brochureFiles[0]?.file?.name}
                            />
                        </a> 
                        :
                        <iframe
                            src={URL.createObjectURL(brochureFiles[0]?.file ?? "")}
                            title="PDF Viewer"
                            // width="100%"
                            // height="500px"
                          />  }                            
                          <Button
                            className="position-absolute"
                            style={{ top: "0", right: "0" }}
                            color="danger"
                            onClick={() => removeBrochureImage(0)}
                          >
                            <Icon name="trash"> </Icon>
                          </Button>
                        </Card>
                      </LazyLoadComponent>
                    </div>
                  </section>
                )}
              </div>
            </Col>
          </Row>
          <div className="actions clearfix">
            <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2 justify-content-between">
              <li>
                <Button color="primary" type="submit">
                  Save
                </Button>
              </li>
              <li>
                <Button color="light" onClick={props.prev} disabled>
                  Previous
                </Button>
              </li>
            </ul>
          </div>
        </form>
      </Block>
    </React.Fragment>
  );
};
export default AddOffPlanStepFiveEdit;
