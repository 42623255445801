import React from "react";
import { Spinner } from "reactstrap";
import { RSelect } from "../../../../components/Component";

const CommunityFilterBase = ({ multiSelect, onChangeCallback, selected, communityFilterOptions, communitiesLoading, styles, placeholder, ...props }) => {
  return (
    <RSelect
      placeholder={placeholder ?? "Community"}
      className="status-div"
      isMulti={multiSelect}
      isLoading={communitiesLoading}
      loadingMessage={() => <Spinner />}
      value={selected}
      options={communityFilterOptions}
      onChange={(option) => onChangeCallback(option)}
      styles={styles ?? {
        option: (provided) => ({
          ...provided,
          whiteSpace: "normal",
          "&:hover": {
            whiteSpace: "normal",
          },
        }),
        menu: (provided) => ({
          ...provided,
          whiteSpace: "normal",
        }),
      }}
      {...props}
    />
  );
};

export default CommunityFilterBase;