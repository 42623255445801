import { useForm } from "react-hook-form";
import React, { useState, useEffect, useRef } from "react";
import Head from "../../../layout/head/Head";
import { Button, Card, CardImg, Col, Input, Label, Row, Spinner } from "reactstrap";
import { Block, Icon, RSelect } from "../../../components/Component";
import AddFooterTemplate from "./AddFooterTemplate";
import { LazyLoadComponent } from "react-lazy-load-image-component";
import AddDeveloperModal from "../../components/popup/AddDeveloperModal";
import { TagsInput } from "react-tag-input-component";
import { deleteFromS3, uploadToS3 } from "../../../utils/envConfig";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import useDragAndDrop from "../../../utils/hooks/useDragAndDrop";
import InputFile from "./InputFile";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import uuid from "react-uuid";
import { useNavigate } from "react-router-dom";

const AddOffPlanStepThreeEdit = (props) => {
  let {
    development,
    setDevelopment,
    setFilesToUpload,
    setFinalPlanDetails,
    offPlanUpdate,
    optDefaultDeveloper = null,
    optDefaultFooter = null,
    developmentLogoFilesDev,
    edit = false,
  } = props;
  const axios = useAxiosPrivate();
  const navigate = useNavigate();
  const { errors, register, handleSubmit } = useForm();
  const [tags, setTags] = useState(development?.keywords);
  const [disabled, setDisabled] = useState(false);
  const [toBeUpdated, setToBeUpdated] = useState({});
  const {
    active: developmentLogoActive,
    errorMessage: developmentLogoStatus,
    dragenter: developmentLogoDragEnter,
    dragleave: developmentLogoDragLeave,
    drop: developmentLogoDrop,
    handleFileChange: developmentLogoFileChange,
    files: developmentLogoFiles,
    setFiles: setDevelopmentLogoFiles,
    removeImage: removeDevelopmentLogoImage,
  } = useDragAndDrop({
    inputType: "image",
  });

  useEffect(() => {
    setDevelopment((prevState) => ({
      ...prevState,
      keywords: tags,
    }));
    setToBeUpdated((prev) => ({
      ...prev,
      keywords: tags,
    }));
  }, [tags]);

  useEffect(() => {
    setDevelopmentLogoFiles(developmentLogoFilesDev);
  }, [developmentLogoFilesDev]);

  const [searchDeveloper, setSearchDeveloper] = useState("");
  const [selectedFooter, setSelectedFooter] = useState(optDefaultFooter?.value);
  const selectFooterDropdownRef = useRef(null);

  const [developer, setDeveloper] = useState(optDefaultDeveloper?.value);

  const [searchFooter, setSearchFooter] = useState("");

  const [developers, setDevelopers] = useState([]);

  const [footerTemplates, setFooterTemplates] = useState([]);

  const filteredDevelopers = developers?.filter((item) =>
    item?.name?.toLowerCase()?.includes(searchDeveloper?.toLowerCase())
  );

  const submitForm = async (data) => {
    const devFile = developmentLogoFiles[0];
    if (!developer || !selectedFooter || !devFile?.file)
      return alert("Please add footer and developer");

    try {
      setDisabled(true);

      let fileName;

      if (typeof devFile?.file !== "string") {
        const filename = `offPlans/photos/${uuid()}.${devFile?.file?.name
          ?.split(".")
          ?.pop()}`;

        fileName = filename;

        await uploadToS3(devFile?.file, filename, "image/*",axios);

        await deleteFromS3(devFile?.prevFileName,axios);
      }

      const params = { ...toBeUpdated };
      if (fileName) {
        params.developmentLogo = fileName;
      }

      await offPlanUpdate(params);
      setTimeout(() => {
        navigate("/off-plans-list");
      }, 2000);
    } catch (err) {
      toast.error(err.message);
      // console.log(err);
    } finally {
      setDisabled(false);
    }
  };

  const [isOpenFooterModal, setIsOpenFooterModal] = useState(false);

  const toggleModalFooter = () => {
    setIsOpenFooterModal(!isOpenFooterModal);
  };

  const [isOpenDeveloperModal, setIsOpenDeveloperModal] = useState(false);

  const toggleModalDeveloper = () => {
    setIsOpenDeveloperModal(!isOpenDeveloperModal);
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    setDevelopment((prev) => ({
      ...prev,
      [name]: value,
    }));
    setToBeUpdated((prev) => ({
      ...prev,
      [name]: value?.trim(),
    }));
  };
  // const developers = [
  //     { value: '1', label: 'Damac' },
  //     { value: '2', label: 'Emmar' },
  //     { value: '3', label: 'Townhouse ' },
  // ];
  const Footers = [
    { value: "1", label: "Urban" },
    { value: "2", label: "Desert" },
    { value: "3", label: "Beach " },
  ];

  const [uploadedImages, setUploadedImages] = useState([]);

  const handleDrop = (acceptedFiles) => {
    setUploadedImages([...uploadedImages, ...acceptedFiles]);
  };

  const handleDelete = (file) => {
    const updatedImages = uploadedImages.filter(
      (uploadedFile) => uploadedFile !== file
    );
    setUploadedImages(updatedImages);
  };

  const getFootersAndDevelopers = async () => {
    try {
      const developersReq = axios.get(`/off-plan/plan-developer`);

      const footersReq = axios.get(`/off-plan/footer-template`);

      const [{ data: developersRes }, { data: footersRes }] = await Promise.all(
        [developersReq, footersReq]
      );
      footersRes && setFooterTemplates(footersRes);
      developersRes && setDevelopers(developersRes);
    } catch (err) {
      // console.log(err);
    }
  };

  useEffect(() => {
    getFootersAndDevelopers();
  }, []);

  return (
    <React.Fragment>
      <Head title="Off Plan"></Head>
      <Block>
        <form id="add-development-form" onSubmit={handleSubmit(submitForm)}>
          <Row className="gy-4">
            <Col sm={12}>
              <div className="form-group">
                <label className="form-label">Select Developer</label>
                <div className="d-flex align-items-center">
                  <div className="flex-grow-1">
                    <RSelect
                      defaultValue={optDefaultDeveloper}
                      options={filteredDevelopers.map((dev) => ({
                        value: dev._id ?? dev?.id,
                        label: dev.name,
                      }))}
                      isMulti={false}
                      onChange={(selectedOption) => {
                        setDeveloper(selectedOption.value);
                        setToBeUpdated((prev) => ({
                          ...prev,
                          developer: selectedOption.value,
                        }));
                      }}
                    />
                  </div>
                  <Button
                    color="primary"
                    className="ms-2"
                    type="button"
                    onClick={toggleModalDeveloper}
                  >
                    <Icon name="plus"></Icon> Add New Developer
                  </Button>
                </div>
              </div>

              <AddDeveloperModal
                isOpen={isOpenDeveloperModal}
                toggle={toggleModalDeveloper}
              />
            </Col>
            <Col sm={12}>
              <div className="form-group">
                <label className="form-label">Footer Template</label>
                <div className="d-flex align-items-center">
                  <div className="flex-grow-1">
                    <RSelect
                      defaultValue={optDefaultFooter}
                      options={footerTemplates
                        ?.filter((temp) =>
                          temp?.name
                            ?.toLowerCase()
                            ?.includes(searchFooter?.toLowerCase())
                        )
                        .map((template) => ({
                          value: template.id,
                          label: template.name,
                        }))}
                      isMulti={false}
                      onChange={(selectedOption) => {
                        setSelectedFooter(selectedOption?.value);
                        setToBeUpdated((prev) => ({
                          ...prev,
                          footer: selectedOption?.value,
                        }));
                      }}
                    />
                  </div>
                  <Button
                    color="primary"
                    className="ms-2"
                    type="button"
                    onClick={toggleModalFooter}
                  >
                    <Icon name="plus"></Icon> Add New Footer
                  </Button>
                </div>
              </div>

              <AddFooterTemplate
                isOpen={isOpenFooterModal}
                toggle={toggleModalFooter}
              />
            </Col>
            <Col>
              <Label>Development Logo</Label>
              <div>
                <InputFile
                  active={developmentLogoActive}
                  dragenter={developmentLogoDragEnter}
                  dragleave={developmentLogoDragLeave}
                  drop={developmentLogoDrop}
                  errorMessage={developmentLogoStatus}
                  files={developmentLogoFiles}
                  handleFileChange={developmentLogoFileChange}
                  removeImage={removeDevelopmentLogoImage}
                  inputType="image"
                  inputProps={{ accept: "image/*" }}
                  multiple
                />
                {/* <Dropzone maxFiles={1} onDrop={ developmentLogoFileChange }>
                                    { ( { getRootProps, getInputProps } ) => (
                                        <section>
                                            <div
                                                { ...getRootProps() }
                                                className="dropzone upload-zone small bg-lighter my-2 dz-clickable"
                                            >
                                                <input { ...getInputProps( { accept: 'image/*' } ) } />
                                                <div className="dz-message">
                                                    <span className="dz-message-text">
                                                        <span>Drag and drop</span> image here or <span>browse</span>
                                                    </span>
                                                </div>
                                            </div>
                                        </section>
                                    ) }
                                </Dropzone> */}

                {(developmentLogoFiles?.length ?? 0) > 0 && (
                  <section>
                    <h6 className="mt-2 mb-2">Uploaded Images</h6>
                    <div className="image-list">
                      <LazyLoadComponent
                        key={developmentLogoFiles[0]?.file?.name}
                      >
                        <Card
                          className="position-relative"
                          style={{ width: "15rem" }}
                        >
                          <CardImg
                            src={
                              !developmentLogoFiles[0]?.file
                                ? null
                                : typeof developmentLogoFiles[0]?.file ===
                                  "string"
                                ? developmentLogoFiles[0]?.file
                                : URL.createObjectURL(
                                    developmentLogoFiles[0]?.file
                                  )
                            }
                            alt={developmentLogoFiles[0]?.file?.name}
                          />

                          <Button
                            className="position-absolute"
                            style={{ top: "0", left: "0" }}
                            color="danger"
                            onClick={() => removeDevelopmentLogoImage(0)}
                          >
                            <Icon name="trash"> </Icon>
                          </Button>
                        </Card>
                      </LazyLoadComponent>
                    </div>
                  </section>
                )}
              </div>
            </Col>
            <Col sm="12">
              <div className="form-group">
                <Label className="form-label" htmlFor="city">
                  keywords
                </Label>
                <TagsInput
                  value={tags}
                  onChange={(newTags) => setTags(newTags)}
                  name="Tags"
                  placeHolder="enter tags then press enter"
                />
              </div>
            </Col>
            <Col sm="12">
              <div className="form-group">
                <Label className="form-label" htmlFor="city">
                  Meta Title
                </Label>
                <Input
                  type="text"
                  name="metaTitle"
                  defaultValue={development?.metaTitle}
                  onChange={handleChange}
                  placeholder="enter off plan meta title"
                ></Input>
              </div>
            </Col>
            <Col sm="12">
              <div className="form-group">
                <label className="form-label" htmlFor="city">
                  Meta Description
                </label>
                <textarea
                  type="textarea"
                  className="form-control form-control-sm"
                  rows="5"
                  placeholder="Write Meta Description"
                  name="metaDescription"
                  value={development.metaDescription}
                  onChange={handleChange}
                  id="developmentDesc"
                />
                {/* <QuillComponentNew data={ development.metaDescription } setFormData={ setDevelopment } meta={true}/> */}
              </div>
            </Col>
          </Row>
          <div className="actions clearfix">
            <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2 justify-content-between">
              <li>
                <Button color="primary" type="submit">
                {disabled ? (
                          <>
                              <Spinner size="sm" color="light" />
                              
                          </>
                      ) : (
                          " Save & Next"
                      )}
                 
                </Button>
              </li>
              <li>
                <Button color="light" onClick={props.prev} disabled>
                  Previous
                </Button>
              </li>
            </ul>
          </div>
        </form>
      </Block>
    </React.Fragment>
  );
};

export default AddOffPlanStepThreeEdit;
