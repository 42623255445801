import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Col, Row, Card, Button, Label, FormGroup, Input, CardBody, CardTitle } from "reactstrap";
import Head from "../../../layout/head/Head";
import
{
    Block,
    RSelect,
    Icon
} from "../../../components/Component";
import DatePicker from "react-datepicker";
import moment from 'moment';
import CustomMap from "./CustomMap";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import { BedRooms, paymentPlans, propertyTypes } from "../../../utils/envConfig";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";


const AddOffPlanStepOne = ( props) =>
{
    const apiKey = process.env.REACT_APP_MAP_KEY;
    const axios = useAxiosPrivate();
    let { development, setDevelopment,setFinalPlanDetails,propertyTypeListDev=null,edit=false,offPlanUpdate} = props;
    const templateOptions = [
      { value: '1', label: 'Version 1' },
      { value: '2', label: 'Version 2' },
    ];    

   
    const [slugValid, setSlugValid] = useState(true);
    const [tobeUpdated, setToBeUpdated] = useState({});
    const [paymentPlan, setPaymentPlan] = useState(development.paymentPlan || '');
    const [paymentScheme, setPaymentScheme] = useState(development.paymentScheme);
    const [propertyTypeList, setPropertyTypeList] = useState(propertyTypeListDev);
    const [optDefaultTemplate, setOptDefaultTemplate] = useState({
      label: `Version ${development?.templateVersion || '1'}`,
      value: development?.templateVersion || '1',
    });
    
    useEffect(() => {
      if (development?.templateVersion) {
        setOptDefaultTemplate({
          label: `Version ${development?.templateVersion || '1'}`,
      value: development?.templateVersion || '1',
        });
      }
    }, [development?.templateVersion]);
    const handleChange = (e) => {
        let { name, value } = e.target;
        setDevelopment((prev) => ({
            ...prev,
            [name]: value,
        }));

        if (edit && name === "developmentDesc") {
          name = "developmentDescription";
        }

        edit && setToBeUpdated((prev) => ({
          ...prev,
          [name]: value?.trim(),
      }));
    };

    const handlePaymentSchemeChange = (e) => {
      const { name, value } = e.target;
      setPaymentScheme((prev) => ({
          ...prev,
          [name]: value,
      }));
  };

    const handleDate = (val) =>{
        const formattedDate = moment(val).format('YYYY-MM-DD');
        setDevelopment((prev) => ({
            ...prev,
            "completionDate": formattedDate,
        }));
        edit&&setToBeUpdated((prev) => ({
          ...prev,
          completionDate: formattedDate?.trim(),
      }));
    }
    // useEffect( () =>
    // {
    // setDevelopment( ( prevState ) => ( {
    //     ...prevState,
    //     propertyTypes: propertyTypeList,
    // } ) );
    // }, [ propertyTypeList ] );

    const veriFySlug = async () => {
        try {
            const withSpace = development?.slug?.trim()?.split(" ").length;

            if (withSpace > 1) {
                setSlugValid(false);
                toast.error("Slug must not contain spaces");
                return;
            }
            const { data } = await axios.get(`/off-plan/by-slug/${development?.slug}`
            );

            if (!data) {
                setSlugValid(true);
                return;
            }

            setSlugValid(false);
        } catch (err) {
            // console.log(err);
        }
    };

    const { errors, register, handleSubmit } = useForm();

    const submitForm = async( data ) =>
    {
      const noPropList = propertyTypeList?.find((item) => {
        const isProp = item?.propertyType?.length;
    
        const bedsNotFilled = item?.beds?.find((elem) => {
            if (!elem?.name?.length) {
                toast.error('Please provide a name for all beds.');
                return true;
            }
            if (!/^\d+$/.test(elem?.startingPrice)) {
              toast.error('Starting price should contain only numbers.');
              return true;
          }
            return false;
        });
    
        return !isProp || bedsNotFilled ? item : null;
    });
    
    const noBedsLength = propertyTypeList?.find((item) => !item?.beds?.length);
    
    if (
        !development?.developmentName ||
        !development.emirate ||
        !development.community?.name ||
        !development.community?.position ||
        !development.downpayment ||
        !development.completionDate ||
        !development.developmentDesc ||
        noPropList ||
        noBedsLength ||
        !development?.templateVersion
    ) {
        toast.error('Please fill all required fields.');
        return;
    }
    
            if (!slugValid) {
                return toast.error(
                    "Development name already taken"
                );
            }

        
            !edit&&setFinalPlanDetails((prev) => ({
                ...prev,
                developmentName:
                    development?.developmentName,
                emirate: development.emirate,
                community: development.community,
                slug: development?.slug?.trim(),
                downpayment: Number(
                    development.downpayment
                ),
                completionDate: development.completionDate,
                developmentDescription:
                    development.developmentDesc,
                propertyTypes: propertyTypeList,
                templateVersion:
                    development?.templateVersion,
                paymentPlan:paymentPlan,
                paymentScheme:paymentScheme
            }));
            if(edit){
              let data={
                ...tobeUpdated,
                propertyTypes: propertyTypeList,
                paymentPlan:paymentPlan,
                paymentScheme:paymentScheme
            }
              data&&await offPlanUpdate(data);
            }

            props.next();    
    };

    const [ selectedOptions, setSelectedOptions ] = useState( [] );
    const [ cards, setCards ] = useState( [] );

    // const handleSelectChange = ( selected ) =>
    // {
    //     setSelectedOptions( selected );
    //     const newCards = selected.map( ( option ) => ( {
    //         id: option.value,
    //         type: option.label,
    //         rows: [ { id: Date.now(), beds: '', price: '' } ]
    //     } ) );
    //     setCards( newCards );
    // };
    
    const handleSelectChange = (selectedOptions) => {
        const updatedList = selectedOptions.map((option) => ({
          propertyType: option.value,
          beds: [{name:"",startingPrice:""}],
        }));
        setPropertyTypeList(updatedList);
      };
      const handlePaymentPlanChange = (selectedOption) => {
        setPaymentPlan(selectedOption.value);
      };
      const handleBedChange = (cardIndex, bedIndex, field, value) => {
        const updatedList = [...propertyTypeList];
        updatedList[cardIndex].beds[bedIndex][field] = value;
        setPropertyTypeList(updatedList);
      };
      
      const handleAddBed = (cardIndex) => {
        const updatedList = [...propertyTypeList];
        updatedList[cardIndex].beds.push({ name: "", startingPrice: "" });
        setPropertyTypeList(updatedList);
      };
      
      const handleDeleteBed = (cardIndex, bedIndex) => {
        const updatedList = [...propertyTypeList];
        updatedList[cardIndex].beds.splice(bedIndex, 1);
        setPropertyTypeList(updatedList);
      };

    // const handleAddRow = ( cardIndex ) =>
    // {
    //     const updatedCards = [ ...cards ];
    //     if ( updatedCards[ cardIndex ].rows.length === 0 )
    //     {
    //         const newRow = {
    //             id: Date.now(),
    //             beds: '',
    //             price: ''
    //         };
    //         updatedCards[ cardIndex ].rows.push( newRow );
    //         setCards( updatedCards );
    //     } else
    //     {
    //         const selectedCard = updatedCards[ cardIndex ];
    //         const newRow = {
    //             id: Date.now(),
    //             beds: '',
    //             price: ''
    //         };
    //         selectedCard.rows.push( newRow );
    //         setCards( [ ...updatedCards ] );
    //     }
    // };
    // const handleDeleteRow = ( cardIndex, rowIndex ) =>
    // {
    //     const updatedCards = [ ...cards ];
    //     const selectedCard = updatedCards[ cardIndex ];
    //     selectedCard.rows.splice( rowIndex, 1 );
    //     if ( selectedCard.rows.length === 0 )
    //     {
    //         updatedCards.splice( cardIndex, 1 );
    //         setSelectedOptions( selectedOptions.filter( ( option ) => option.value !== selectedCard.id ) );
    //     }
    //     setCards( updatedCards );
    // };

    // const handleInputChange = ( cardIndex, rowIndex, field, value ) =>
    // {
    //     const updatedCards = [ ...cards ];
    //     updatedCards[ cardIndex ].rows[ rowIndex ][ field ] = value;
    //     setCards( updatedCards );
    // };

    // const handleCancelSelect = () =>
    // {
    //     setSelectedOptions( [] );
    //     setCards( [] );
    // };
    return (
      <React.Fragment>
        <Head title="Off Plan"></Head>
        <Block>
          <form id="add-development-form" onSubmit={handleSubmit(submitForm)}>
            <Row className="gy-4">
            <Col sm="12">
                  <div className="form-group">
                    <Label className=" form-label">Select Template Version *</Label>
                    <div>
                      <RSelect
                        closeMenuOnSelect={false}
                        value={{
                          label: `Version ${development?.templateVersion || '1'}`,
                          value: development?.templateVersion || '1',
                        }}
                        isMulti={false}
                        onChange={(selectedOption) => {
                          const selectedValue = selectedOption ? selectedOption.value : null;                  
                          setDevelopment((prev) => ({
                            ...prev,
                            templateVersion: selectedValue,
                          }));
                          setOptDefaultTemplate(selectedOption);
                  
                          edit &&setToBeUpdated((prev) => ({
                            ...prev,
                            templateVersion: selectedValue,
                          }));
                        }}
                        options={templateOptions}
                        className="w-100"
                      />
                    </div>
                    {errors.label && (
                      <span className="invalid">{errors.label.message}</span>
                    )}
                  </div>
                </Col>
              <Col sm="12">
                <div className="form-group">
                  <label className="form-label">Development Name *</label>
                  <div className="form-control-wrap">
                    <input
                      type="text"
                      id="developmentName"
                      name="developmentName"
                      placeholder="Development Name"
                      className="form-control"
                      defaultValue={development.developmentName}
                      onChange={(e) => {
                        const { value } = e.target;
                        const slug = value
                          ?.trim()
                          ?.toLowerCase()
                          ?.split(" ")
                          ?.join("-");

                        handleChange(e);
                        setDevelopment((prev) => ({
                          ...prev,
                          slug,
                        }));
                        edit&&setToBeUpdated((prev) => ({
                          ...prev,
                          slug,
                      }));
                      }}
                      onBlur={veriFySlug}
                      ref={register({ required: "This field is required" })}
                    />
                  </div>
                  {errors.label && (
                    <span className="invalid">{errors.label.message}</span>
                  )}
                </div>
              </Col>
              <Col sm="12">
                <div className="form-group">
                  <label className="form-label">URL Address</label>
                  <div class="input-group">
                    <span class="input-group-addon" id="basic-addon3">
                      https://alba.homes/off-plan-dubai/
                    </span>
                    <input
                      type="text"
                      class="form-control"
                      id="basic-url"
                      aria-describedby="basic-addon3"
                      name="slug"
                      onBlur={async (e) => {
                        try {
                          const slug = e.target.value;
                          const withSpace = slug?.trim()?.split(" ").length;

                          if (withSpace > 1) {
                            setSlugValid(false);
                            toast.error("Slug must not contain spaces");
                            return;
                          }

                          await veriFySlug();
                        } catch (err) {
                          setSlugValid(false);
                        }
                      }}
                      // readOnly
                      onChange={handleChange}
                      value={development?.slug}
                    />
                  </div>
                </div>
              </Col>
              <Col sm="6">
                <div className="form-group">
                  <label className="form-label">Down Payment *</label>
                  <div class="input-group">
                    <span class="input-group-addon" id="basic-addon3">
                      %
                    </span>
                    <input
                      id="downpayment"
                      type="number"
                      name="downpayment"
                      defaultValue={development?.downpayment}
                      onChange={(e) => {
                        const { value } = e?.target;

                        if (isNaN(value) || Number(value) > 100) {
                          return;
                        }

                        handleChange(e);
                      }}
                      onKeyDown={(e) => {
                        if (e.key === "E" || e.key === "e" || e.key === "-") {
                          e.preventDefault();
                        }
                      }}
                      className="form-control"
                      ref={register({ required: "This field is required" })}
                    />
                  </div>
                  {errors.label && (
                    <span className="invalid">{errors.label.message}</span>
                  )}
                </div>
              </Col>
              <Col sm="6">
                <div className="form-group">
                  <label className="form-label">% payment for On Booking</label>
                  <div class="input-group">
                    <span class="input-group-addon" id="basic-addon3">
                      %
                    </span>
                    <input
                      id="onBooking"
                      type="number"
                      name="onBooking"
                      defaultValue={development?.paymentScheme?.onBooking}
                      onChange={(e) => {
                        const { value } = e?.target;

                        if (isNaN(value) || Number(value) > 100) {
                          return;
                        }

                        handlePaymentSchemeChange(e);
                      }}
                      onKeyDown={(e) => {
                        if (e.key === "E" || e.key === "e" || e.key === "-") {
                          e.preventDefault();
                        }
                      }}
                      className="form-control"
                      // ref={register({ required: "This field is required" })}
                    />
                  </div>
                  {errors.label && (
                    <span className="invalid">{errors.label.message}</span>
                  )}
                </div>
              </Col>
              <Col sm="6">
                <div className="form-group">
                  <label className="form-label">% payment for On Seven Days</label>
                  <div class="input-group">
                    <span class="input-group-addon" id="basic-addon3">
                      %
                    </span>
                    <input
                      id="onSevenDays"
                      type="number"
                      name="onSevenDays"
                      defaultValue={development?.paymentScheme?.onSevenDays}
                      onChange={(e) => {
                        const { value } = e?.target;

                        if (isNaN(value) || Number(value) > 100) {
                          return;
                        }

                        handlePaymentSchemeChange(e);
                      }}
                      onKeyDown={(e) => {
                        if (e.key === "E" || e.key === "e" || e.key === "-") {
                          e.preventDefault();
                        }
                      }}
                      className="form-control"
                      // ref={register({ required: "This field is required" })}
                    />
                  </div>
                  {errors.label && (
                    <span className="invalid">{errors.label.message}</span>
                  )}
                </div>
              </Col>
              <Col sm="6">
                <div className="form-group">
                  <label className="form-label">% payment for On During Construction</label>
                  <div class="input-group">
                    <span class="input-group-addon" id="basic-addon3">
                      %
                    </span>
                    <input
                      id="onDuringConstruction"
                      type="number"
                      name="onDuringConstruction"
                      defaultValue={development?.paymentScheme?.onDuringConstruction}
                      onChange={(e) => {
                        const { value } = e?.target;

                        if (isNaN(value) || Number(value) > 100) {
                          return;
                        }

                        handlePaymentSchemeChange(e);
                      }}
                      onKeyDown={(e) => {
                        if (e.key === "E" || e.key === "e" || e.key === "-") {
                          e.preventDefault();
                        }
                      }}
                      className="form-control"
                      // ref={register({ required: "This field is required" })}
                    />
                  </div>
                  {errors.label && (
                    <span className="invalid">{errors.label.message}</span>
                  )}
                </div>
              </Col>
              <Col sm="6">
                <div className="form-group">
                  <label className="form-label">% payment for On Completion</label>
                  <div class="input-group">
                    <span class="input-group-addon" id="basic-addon3">
                      %
                    </span>
                    <input
                      id="onCompletion"
                      type="number"
                      name="onCompletion"
                      defaultValue={development?.paymentScheme?.onCompletion}
                      onChange={(e) => {
                        const { value } = e?.target;

                        if (isNaN(value) || Number(value) > 100) {
                          return;
                        }

                        handlePaymentSchemeChange(e);
                      }}
                      onKeyDown={(e) => {
                        if (e.key === "E" || e.key === "e" || e.key === "-") {
                          e.preventDefault();
                        }
                      }}
                      className="form-control"
                      // ref={register({ required: "This field is required" })}
                    />
                  </div>
                  {errors.label && (
                    <span className="invalid">{errors.label.message}</span>
                  )}
                </div>
              </Col>
              <Col sm="12">
                  <div className="form-group">
                    <Label className="form-label">Payment Plan *</Label>
                    <div>
                      <RSelect
                        isMulti={false}
                        onChange={handlePaymentPlanChange}
                        options={paymentPlans.map((type) => ({
                          label: type,
                          value: type,
                        }))}
                        value={{
                          label: paymentPlan, 
                          value: paymentPlan,
                        }}
                        className="w-100"
                      />
                    </div>
                    {errors.label && (
                      <span className="invalid">{errors.label.message}</span>
                    )}
                  </div>
                </Col>


              <Col className="col-6">
                <div className="form-group">
                  <label className="form-label">Completion Date *</label>
                  <DatePicker
                    // selected={ rangeStart }
                    // onChange={ setRangeStart }
                    selectsStart
                    // startDate={ rangeStart }
                    // endDate={ rangeEnd }
                    minDate={moment().toDate()}
                    name="completionDate"
                    value={development.completionDate}
                    onChange={handleDate}
                    onFocus={(e) => {
                      e.target.type = "date";
                      e.target.click();
                    }}
                    onBlur={(e) =>
                      !e.target.value ? (e.target.type = "text") : null
                    }
                    wrapperClassName="start-m"
                    className="form-control"
                  ></DatePicker>
                  {errors.label && (
                    <span className="invalid">{errors.label.message}</span>
                  )}
                </div>
              </Col>
              <div>
                <Col sm="12">
                  <div className="form-group">
                    <Label className=" form-label">Choose Type *</Label>
                    <div>
                      <RSelect
                        closeMenuOnSelect={false}
                        isMulti={true}
                        onChange={handleSelectChange}
                        options={propertyTypes.map((type) => ({
                          label: type,
                          value: type,
                        }))}
                        value={propertyTypeList
                          ?.filter((item) => item.propertyType !== null)
                          ?.map((item) => ({
                            label: item.propertyType,
                            value: item.propertyType,
                          }))}
                        className="w-100"
                      />
                    </div>
                    {errors.label && (
                      <span className="invalid">{errors.label.message}</span>
                    )}
                  </div>
                </Col>
                {/* 
                            {propertyTypeList && propertyTypeList?.length>1 &&propertyTypeList?.map( ( card, cardIndex ) => (
                                <Card key={ cardIndex } className="mb-3 mt-3 ">
                                    <div className="card-inner">


                                        <CardBody>
                                            <div className="d-flex align-items-center justify-content-between ">

                                                <CardTitle ><h5>{ card.propertyType }
                                                </h5></CardTitle>

                                                <Button color="secondary" onClick={ () => handleAddRow( cardIndex ) }>
                                                    <Icon name="plus">
                                                    </Icon> <span>Add
                                                    </span>
                                                </Button>


                                            </div>
                                            <hr></hr>


                                            { card?.beds?.map( ( row, rowIndex ) => (
                                                <Row key={ rowIndex} className="mt-1">
                                                    <Col sm="3">
                                                        <FormGroup>
                                                            <Label>Number of Beds * : </Label>

                                                            <div>
                                                                <RSelect isMulti={ false } options={ Beds } />
                                                            </div>


                                                        </FormGroup>
                                                    </Col>
                                                    <Col sm="3">
                                                        <FormGroup>
                                                            <Label>Price * :</Label>
                                                            <div className="d-flex align-items-center">
                                                                <div className="input-group">
                                                                    <span className="input-group-addon" id="basic-addon3">AED</span>
                                                                    <Input
                                                                        type="text"
                                                                        value={ row.price }
                                                                        placeholder="Enter Price"
                                                                        onChange={ ( e ) => handleInputChange( cardIndex, rowIndex, 'price', e.target.value ) }
                                                                    />
                                                                </div>
                                                                <Button color="danger" onClick={ () => handleDeleteRow( cardIndex, rowIndex ) } className="ms-3">
                                                                    <Icon name="trash" />
                                                                </Button>
                                                            </div>
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                            ) ) }
                                        </CardBody>
                                    </div>
                                </Card>
                            ) ) } */}
                {propertyTypeList &&
                  propertyTypeList?.length > 0 &&
                  propertyTypeList?.map(
                    (card, cardIndex) =>
                      card?.propertyType && (
                        <Card key={cardIndex} className="mb-3 mt-3 ">
                          <div className="card-inner">
                            <CardBody>
                              <div className="d-flex align-items-center justify-content-between ">
                                <CardTitle>
                                  <h5>{card.propertyType}</h5>
                                </CardTitle>
                                <Button
                                  color="secondary"
                                  onClick={() => handleAddBed(cardIndex)}
                                >
                                  <Icon name="plus"></Icon> <span>Add</span>
                                </Button>
                              </div>
                              <hr />
                              {card?.beds?.map((bed, bedIndex) => (
                                <Row key={bedIndex} className="mt-1">
                                  <Col sm="3">
                                    <FormGroup>
                                      <Label>Number of Beds * : </Label>
                                      <div>
                                        <RSelect
                                          isMulti={false}
                                          options={BedRooms.map((type) => ({
                                            label: type,
                                            value: type,
                                          }))}
                                          onChange={(selectedOption) =>
                                            handleBedChange(
                                              cardIndex,
                                              bedIndex,
                                              "name",
                                              selectedOption.value
                                            )
                                          }
                                          value={
                                            bed.name
                                              ? {
                                                  label: bed.name,
                                                  value: bed.name,
                                                }
                                              : null
                                          }
                                        />
                                      </div>
                                    </FormGroup>
                                  </Col>
                                  <Col sm="3">
                                    <FormGroup>
                                      <Label>Starting Price * :</Label>
                                      <div className="d-flex align-items-center">
                                        <div className="input-group">
                                          <span
                                            className="input-group-addon"
                                            id="basic-addon3"
                                          >
                                            AED
                                          </span>
                                          <Input
                                            type="number"
                                            value={bed?.startingPrice}
                                            placeholder="Enter Starting Price"
                                            onChange={(e) =>
                                              handleBedChange(
                                                cardIndex,
                                                bedIndex,
                                                "startingPrice",
                                                e.target.value
                                              )
                                            }
                                          />
                                        </div>
                                        <Button
                                          color="danger"
                                          onClick={() =>
                                            handleDeleteBed(cardIndex, bedIndex)
                                          }
                                          className="ms-3"
                                        >
                                          <Icon name="trash" />
                                        </Button>
                                      </div>
                                    </FormGroup>
                                  </Col>
                                </Row>
                              ))}
                            </CardBody>
                          </div>
                        </Card>
                      )
                  )}
              </div>
              <Col sm="12">
                <div className="form-group">
                  <Label className="form-label" htmlFor="fv-message">
                    Description *
                  </Label>
                  <div className="form-control-wrap">
                    <textarea
                      type="textarea"
                      className="form-control form-control-sm"
                      rows="5"
                      placeholder="Write Description"
                      name="developmentDesc"
                      defaultValue={development?.developmentDesc}
                      onChange={handleChange}
                      id="developmentDesc"
                    />
                  </div>
                </div>
              </Col>
              <Col sm="12">
                <div className="form-group">
                  <label className="form-label">Location *</label>
                  {!edit ?
                  <CustomMap
                    apiKey={apiKey}
                    setCommunity={(community) => {
                      setDevelopment((prev) => ({
                        ...prev,
                        emirate: community.emirate,
                        community: {
                          name: community?.name,
                          position: community?.position,
                        },
                      }));
                    }}
                  />
                  :
                  <CustomMap
                  apiKey={apiKey}
                  setCommunity={(community) => {
                    setDevelopment((prev) => ({
                      ...prev,
                      emirate: community.emirate,
                      community: {
                        name: community?.name,
                        position: community?.position,
                      },
                    }));
                  }}
                  community={{
                    ...development?.community,
                    emirate: development?.emirate,
                }}
                />
                }
                  {/* <LoadScript googleMapsApiKey={ process.env.REACT_APP_MAP_KEY }>
                                    <GoogleMap
                                        mapContainerStyle={ mapStyles }
                                        center={ center }
                                        zoom={ 14 }
                                    >
                        
                                        <Marker position={ center } />
                                    </GoogleMap>
                                </LoadScript> */}
                </div>
              </Col>
            </Row>
            <div className="actions clearfix">
              <ul>
                <li>
                  <Button
                    color="primary"
                    type="submit"
                    className="rounded-lg border bg-[#3399db] text-white py-2 px-6"
                  >
                   {edit? "Save & Next ":"Next"}
                  </Button>
                </li>
              </ul>
            </div>
          </form>
        </Block>
      </React.Fragment>
    );
};

export default AddOffPlanStepOne;