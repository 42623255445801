import { Card, CardBody, Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';
import { AgentReportContext, HotLeadsTableView, HotLeadTableTabs } from '../../../AgentReportLayout';
import { useContext, useState } from 'react';
import { classNames } from '@react-pdf-viewer/core';
import _ from 'lodash';
import { HotLeadsQueries, useHotLeadsQuery } from '../hooks/useHotLeadsQuery';
import Skeleton from 'react-loading-skeleton';
import { Icon, PaginationComponent } from '../../../../../../components/Component';
import StatusBadge from '../../../../../explorer/leads-view/components/StatusPill';
import { STATUS_COLORS } from '../../../../../explorer/leads-view/constants';
import { addMilliseconds } from 'date-fns';
import { Link } from 'react-router-dom';
import LeadSourceFilter from '../../../../../components/filter-components/LeadSourceFilter';
import SelectAgent from '../../../../../../components/SelectAgent';
import AgentTeamFilter from '../../../../../components/filter-components/AgentTeamFilter';
import { ActivityTypes } from '../../../../../../utils/envConfig';
import { formatMilliseconds, getTimeUntil } from '../../../../../../utils/Utils';

const HotLeadsTable = () => {
  const { activeTableTab, switchTab, tableView, selectedTeamLeader, setSelectedTeamLeader, selectedQuery, setSelectedQuery } = useContext(AgentReportContext);
  const [pageIndex, setPageIndex] = useState(1);
  const [selectedLeadSource, setSelectedLeadSource] = useState(null);
  const [selectedAgent, setSelectedAgent] = useState(null);

  const query = useHotLeadsQuery(selectedQuery, {
    leadSource: selectedLeadSource?.value,
    agents: selectedAgent?.id ?? selectedAgent?._id,
    leader: selectedTeamLeader?.userId,
    page: pageIndex
  });

  return (
    <Card className="p-4">
      <Nav tabs className="d-flex justify-content-between">
        {HotLeadTableTabs.map((tab) => (
          <NavItem key={`hot-lead-tab-${tab.key}`}>
            <NavLink
              style={{ cursor: 'pointer' }}
              className={classNames({
                active: _.isEqual(activeTableTab, tab)
              })}
              onClick={() => {
                switchTab(tab.key);
                setPageIndex(1);
                setSelectedQuery(tab.name);
                setSelectedLeadSource(null);
                setSelectedAgent(null);
                setSelectedTeamLeader(null);
              }}
            >
              {tab.label}
            </NavLink>
          </NavItem>
        ))}
      </Nav>
      <div className="d-flex justify-content-start w-100 pb-2 mt-4" style={{ gap: '1rem' }}>
        <div className="flex-grow-1 w-50 position-relative">
          <LeadSourceFilter
            stateVar={selectedLeadSource}
            hotOnly
            onChange={(option) => {
              setSelectedLeadSource((_) => option);
            }}
          />
          {selectedLeadSource && (
            <span
              className="fs-6 position-absolute text-danger"
              style={{ top: 7, right: 30 }}
              role="button"
              onClick={() => setSelectedLeadSource(null)}
            >
              <Icon name={'cross'} />
            </span>
          )}
        </div>
        <div className="flex-grow-1 w-50 position-relative">
          <SelectAgent
            isClearable
            notStyled
            selectedAgent={selectedAgent}
            onSelectAgent={(agent) => {
              setSelectedAgent((_) => agent);
              setPageIndex(1);
            }}
          />
          {selectedAgent && (
            <span
              className="fs-6 position-absolute text-danger"
              style={{ top: 7, right: 30 }}
              role="button"
              onClick={() => { setSelectedAgent(null); setPageIndex(1); }}
            >
              <Icon name={'cross'} />
            </span>
          )}
        </div>
        {tableView === HotLeadsTableView.Team ? (
          <div className="flex-grow-1 w-50 position-relative">
            <AgentTeamFilter
              stateVar={selectedTeamLeader}
              onChange={(option) => {
                setSelectedAgent(_ => null);
                setSelectedTeamLeader((_) => option);
                setPageIndex(1);
              }}
            />
            {selectedTeamLeader && (
              <span
                className="fs-6 position-absolute text-danger"
                style={{ top: 7, right: 30 }}
                role="button"
                onClick={() => { setSelectedTeamLeader((_) => null); setPageIndex(1); }}
              >
                <Icon name={'cross'} />
              </span>
            )}
          </div>
        ) : null}
      </div>
      <TabContent activeTab={activeTableTab.key}>
        {HotLeadTableTabs.map((tab) => (
          <TabPane tabId={tab.key} key={`hot-lead-tab-content-${tab.key}`}>
            {!query.isLoading && !query?.data?.found && !query.isError ? (
              <Card>
                <CardBody className="text-center align-middle">No {tab.readableName} leads found</CardBody>
              </Card>
            ) : null}

            {query.isLoading && !query.isError ? <HotLeadsTableLoader /> : null}

            {query.isError ? (
              <div
                className="p-2 text-danger d-flex flex-column justify-content-center align-items-center"
              >
                <p>Failed to fetch hot leads.</p>
                <span className="text-primary" role="button" onClick={() => query.refetch()}>
                  Try again
                </span>
              </div>
            ) : null}

            {!query.isLoading && !query.isError && query?.data?.found ? (
              <div>
                <div className="nk-tb-list border rounded">
                  <TableHeader selectedQuery={selectedQuery} />
                  {selectedQuery === HotLeadsQueries.Expired ? (
                    query.data?.data?.map((item, idx) => (
                      <div className="nk-tb-item" key={`hot-lead-table-item-${item?.id ?? idx + 1}`}>
                        <div className="nk-tb-col text-start" style={{ maxWidth: '25ch' }}>
                          <Link
                            to={`/user-profile/${
                              item?.lead?.id ?? item?.lead?._id
                            }/user-profile-setting`}
                            target='_blank'
                          >
                            {`${item?.lead?.name}`}
                          </Link>
                        </div>
                        <div className="nk-tb-col text-center">{item?.lead?.phone ?? 'Unknown'}</div>
                        <div className="nk-tb-col text-center">
                          {item?.agent?.name ?? item?.lea?.currentAgent?.user?.first_name ?? 'Unknown'}
                        </div>
                        <div className="nk-tb-col text-center">
                          <StatusBadge
                            style={{ width: '5rem', marginInline: 'auto' }}
                            status={item?.lead?.leadStatus ?? ''}
                            color={STATUS_COLORS[item?.lead?.leadStatus] ?? 'primary'}
                          />
                        </div>
                        <div className="nk-tb-col text-center text-nowrap">
                          -
                        </div>
                        <div className="nk-tb-col text-center text-nowrap">
                            {item?.createdAt ? getTimeUntil(
                            addMilliseconds(
                              new Date(),
                              - (Date.now() - new Date(item?.createdAt).getTime())
                            ),
                            { addSuffix: true, roundTime: false }
                          ) : '-'}
                        </div>
                        <div className="nk-tb-col text-end">
                          {item?.lead?.leadSource?.name ?? 'Unknown'}
                        </div>
                      </div>
                    ))
                  ) : (query.data?.data?.map((item, idx) => {
                    const lastAssignActivity = item?.activities.find((activity) => activity.type === ActivityTypes.Assign);

                    return (
                      <div
                        className="nk-tb-item"
                        key={`hot-lead-table-item-${item?.id ?? idx + 1}`}
                      >
                        <div className="nk-tb-col text-start" style={{ maxWidth: '25ch' }}>
                          <Link
                            to={`/user-profile/${item?._id?.user}/user-profile-setting`}
                            target="_blank"
                          >
                            {`${item?.user?.first_name} ${item?.user?.last_name ?? ''}`}
                          </Link>
                        </div>
                        <div className="nk-tb-col text-center">
                          {item?.user?.phone ?? 'Unknown'}
                        </div>
                        <div className="nk-tb-col text-center">
                          {item?.responsibleAgent?.user?.first_name ??
                            item?.responsibleAgent?.user?.firstName ??
                            item?.user?.currentAgent?.user?.first_name ??
                            'Unknown'}
                        </div>
                        <div className="nk-tb-col text-center">
                          <StatusBadge
                            style={{ width: '5rem', marginInline: 'auto' }}
                            status={item?.user?.lead_status ?? ''}
                            color={STATUS_COLORS[item?.user?.lead_status] ?? 'primary'}
                          />
                        </div>
                        <div className="nk-tb-col text-center text-nowrap">
                          {lastAssignActivity?.completedTimeStamp
                            ? getTimeUntil(
                                addMilliseconds(
                                  new Date(),
                                  -(Date.now() - (lastAssignActivity?.completedTimeStamp ?? 0)) //* The difference in milliseconds between the current time and the completed time
                                ),
                                { addSuffix: true, roundTime: false }
                              )
                            : '-'}
                        </div>
                        <div className="nk-tb-col text-center text-nowrap py-4">
                          <div className='position-relative'>
                            <span>
                              {item?.activities[0].completedTimeStamp
                                ? getTimeUntil(
                                    addMilliseconds(
                                      new Date(),
                                      -(Date.now() - (item?.activities[0].completedTimeStamp ?? 0))
                                    ),
                                    { addSuffix: true, roundTime: false }
                                  )
                                : '-'}
                            </span>
                            <span className='text-danger position-absolute' style={{ bottom: -20, left: '50%', transform: 'translateX(-50%)', fontSize: '0.65rem' }}>
                            {(item?.activities[0].completedTimeStamp ?? 0) - (lastAssignActivity.completedTimeStamp ?? 0)
                                ? `${formatMilliseconds(
                                  ((item?.activities[0].completedTimeStamp ?? 0) - (lastAssignActivity.completedTimeStamp ?? 0)),
                                  ['days', 'hours', 'minutes', 'seconds']
                                )} later`
                                : '-'}
                            </span>
                          </div>
                        </div>
                        <div className="nk-tb-col text-end">
                          {item?.user?.leadSource?.name ?? 'Unknown'}
                        </div>
                      </div>
                    );
                  }))}
                </div>

                <div className="d-flex justify-content-end mt-4">
                  <PaginationComponent
                    currentPage={query?.data?.page ?? pageIndex ?? 1}
                    itemPerPage={query?.data?.itemsPerPage ?? 10}
                    totalItems={query?.data?.found ?? 0}
                    paginate={(page) => {
                      setPageIndex((_) => page);
                    }}
                  />
                </div>
              </div>
            ) : null}
          </TabPane>
        ))}
      </TabContent>
    </Card>
  );
};

const TableHeader = ({ selectedQuery = HotLeadsQueries.Assigned }) => (
  <div className="nk-tb-item nk-tb-head">
    <div className="nk-tb-col fw-semibold text-secondary text-start">
      <span>Name</span>
    </div>
    <div className="nk-tb-col fw-semibold text-secondary text-center">
      <span className="text-center">Phone</span>
    </div>
    <div className="nk-tb-col fw-semibold text-secondary text-center">
      <span>Responsible Agent</span>
    </div>
    <div className="nk-tb-col fw-semibold text-secondary text-center">
      <span>Status</span>
    </div>
    <div className="nk-tb-col fw-semibold text-secondary text-center">
      <span>Assigned At</span>
    </div>
    <div className="nk-tb-col fw-semibold text-secondary text-center">
      <span>{selectedQuery === HotLeadsQueries.Expired ? "Expired" : "Last Activity At"}</span>
    </div>
    <div className="nk-tb-col fw-semibold text-secondary text-end">
      <span>Lead Source</span>
    </div>
  </div>
);

const HotLeadsTableLoader = ({ rowCount = 5 }) => {
  return (
    <div>
      <div className="nk-tb-list border rounded">
        <TableHeader />
        {Array(rowCount)
          .fill(0)
          .map((_, idx) => (
            <div className="nk-tb-item" key={`hot-lead-table-skeleton-${idx}`}>
              <div className="nk-tb-col">
                <Skeleton />
              </div>
              <div className="nk-tb-col">
                <Skeleton />
              </div>
              <div className="nk-tb-col">
                <Skeleton />
              </div>
              <div className="nk-tb-col">
                <Skeleton />
              </div>
              <div className="nk-tb-col">
                <Skeleton />
              </div>
              <div className="nk-tb-col">
                <Skeleton />
              </div>
              <div className="nk-tb-col">
                <Skeleton />
              </div>
            </div>
          ))}
      </div>
      <div className="d-flex mt-2 justify-content-end">
        <Skeleton width={200} height={30} />
      </div>
    </div>
  );
};
export default HotLeadsTable;
