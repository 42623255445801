import { useQuery } from 'react-query';
import useAxiosPrivate from '../../../hooks/useAxiosPrivate';
import { timeAsMilliseconds } from '../../../utils/Utils';
import { RSelect } from '../../../components/Component';
import { components } from 'react-select';
import { Badge, Spinner } from 'reactstrap';
import { useMemo } from 'react';
import { LeadSourceType } from '../../../utils/envConfig';

const LeadSourceFilter = ({ stateVar, onChange, hotOnly = false }) => {
  const axios = useAxiosPrivate();

  const query = useQuery(
    ['allLeadSources'],
    async () => (await axios.get('/leadSources', { params: hotOnly ? { limit: 1000, leadSourceType: LeadSourceType.HOT } : { limit: 1000 } })).data,
    { refetchOnWindowFocus: false, refetchInterval: timeAsMilliseconds({ hours: 1 }) }
  );

  return (
    <RSelect
      placeholder="Choose Lead Source"
      options={query.data?.data?.map((leadSource) => ({
        value: leadSource?.id ?? leadSource?._id,
        label: `${leadSource?.name}`,
        ...leadSource
      })) ?? []}
      components={{ Option: Option, NoOptionsMessage: NoOptionsMessage }}
      loadingMessage={() => <Spinner />}
      value={stateVar}
      error={query.error}
      isLoading={query.isLoading}
      onChange={onChange}
    //   className='status-div'
    />
  );
};

const NoOptionsMessage = (props) => {
  const { error } = props.selectProps;

  return error ? (
    <div className="p-2 text-danger">Failed to load lead sources: {error.message}</div>
  ) : (
    <components.NoOptionsMessage {...props}>No options available</components.NoOptionsMessage>
  );
};

const Option = (props) => {
  const isHot = useMemo(() => {
    if (props?.data?.leadSourceType === LeadSourceType.HOT) {
      return true;
    }
    return false;
  }, [props?.data?.leadSourceType]);

  return (
    <components.Option {...props}>
      <div className="d-flex justify-content-between">
        <span>{`${props?.data?.label}`}</span>
        <Badge color={isHot ? 'danger' : 'secondary'}>{`${props?.data?.leadSourceType}`}</Badge>
      </div>
    </components.Option>
  );
};

export default LeadSourceFilter;
