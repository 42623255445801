import React, { Suspense, useEffect } from 'react';
import {
    Routes,
    Route,
    useLocation,
    useNavigate,
} from 'react-router-dom';

import Error404Classic from '../pages/error/404-classic';

// PROPERTY SETTING PAGES
import CategoryList from '../pages/property-settings/CategoriesList';
import PropertyTypeList from '../pages/property-settings/PropertyTypeList';
import AmenityCategoryList from '../pages/property-settings/AmenityCategoryList';
import AmenityList from '../pages/property-settings/AmenityList';
import CommunityList from '../pages/property-settings/CommunityList';
import BuildingList from '../pages/property-settings/BuildingList';

// USERS PAGES (including agents)
import UsersList from '../pages/users/UsersList';
import LandLordsList from '../pages/users/LandLords';
import BuyersList from '../pages/users/BuyersList';
import AgentList from '../pages/users/AgentList';
import TenantsList from '../pages/users/TenantsList';

// LEADS PAGES
import StatusBoard from '../pages/leads/StatusBoard';
import LeadsUpload from '../pages/leads/LeadsUpload';
import LeadsLists from '../pages/leads/LeadsLists';
import UserProfileLayout from '../pages/users/UserProfile';

//DND NUMBERS
import DndLeads from '../pages/dnd/DndLeads';
import DndNumbersUploads from '../pages/dnd/DndNumbersUpload';

// EXPLORER PAGES
import ImportData from '../pages/explorer/ImportData';
import ListPropertyCards from '../pages/explorer/ListPropertyCards';
import PropertyCardDetailPage from '../pages/explorer/PropertyCardDetailPage';
import ListPocketListing from '../pages/explorer/ListPocketListing';

// COMMUNITY TEAMS PAGES
import ListCommunityTeams from '../pages/community-teams/ListCommunityTeams';

// DEALS PAGES
import DealList from '../pages/deals/DealList';
import AddDeal from '../pages/deals/AddDeal';
import EditDeal from '../pages/deals/EditDeal';
import DealDetails from '../pages/deals/DealDetails';

// PROPERTIES PAGES
import AddProperty from '../pages/properties/AddProperty';
import EditProperty from '../pages/properties/EditProperty';
import PropertyList from '../pages/properties/PropertyList';
import PropertyDetails from '../pages/properties/PropertyDetails';

// PROPERTIES PAGES
import AddAgency from '../pages/agencies/AddAgency';

// DASHBOARD PAGES
import MainDashboard from '../pages/dashboards';
import AgentBoardByAdmin from '../pages/dashboards/AgentBoardByAdmin';
import MarketingDashboard from '../pages/dashboards/MarketingDashboard';
import ListingPerformance from '../pages/dashboards/ListingPerformance';
import OffplanMarketingDashboard from '../pages/dashboards/OffplanMarketingDashboard';
import DealsClosedAnalysis from '../pages/dashboards/DealsClosedAnalysis';
import LiveFeed from '../pages/dashboards/LiveFeed';

// OFFPLAN PAGES
import OffPlansList from '../pages/off-plans/OffplansList';

// BLOGS PAGES
import Blogs from '../pages/blogs/Blogs';
import AddNewDevelopment from '../pages/off-plans/AddOffPlan';
import CreateLead from '../pages/leads/CreateLead';

import AllDevelopers from '../pages/off-plans/components/AllDevelopers';
import FooterTemplates from '../pages/off-plans/components/FooterTemplates';
import EditDevelopment from '../pages/off-plans/EditOffPlan';
import ChangePassword from '../pages/auth/ChangePassword';

import StaffList from '../pages/users/StaffList';
import CommunityTeamDetails from '../pages/community-teams/CommunityTeamDetails';
import ListCheckedOutProperties from '../pages/explorer/ListCheckedOutProperties';
import AppSettings from '../pages/app-settings/AppSettings';
import NewLeadsUpload from '../pages/leads/NewLeadsUpload';
import PropertiesXml from '../pages/properties/PropertiesXml';
import PropertyDetailsXml from '../pages/properties/PropertyDetailsXml';
import CreatePocketListing from '../pages/explorer/pocket-listing/CreatePocketListing';
import Vacation from '../pages/vacation-leaving/Vacation';

// Yeastar PBX Dialer
import PBXDialer from '../pages/pbx-yeastar/PBXDialer';
import PBXCore from '../pages/pbx-yeastar/PBXCore';
import CallReportListContainer from '../pages/pbx-yeastar/tables/call-report-list/CallReportListContainer';
import { useSelector } from 'react-redux';
import { getRoute } from '../layout/menu/MenuData';
import ExplorerLeadsContainer from '../pages/explorer/leads-view/ExplorerLeadsContainer';
import Profile from '../pages/profile-page/Profile';
import DealCommissionsContainer from '../pages/finances/deal-commissions/DealCommissionsContainer';
import LeadSourcesList from '../pages/leads/LeadSourcesList';
import FinancesViewContainer from '../pages/finances/finances-view/FinancesViewContainer';
import DealsKanban from '../pages/deals/kanban-board/DealsKanban';
import AgentReportLayout from '../pages/war-front/agent-report/AgentReportLayout';


const ScrollToTop = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return null;
};

const Pages = () => {
    const user = useSelector((state) => state?.user?.loggedInUser);
    const location = useLocation();

    const navigate = useNavigate();

    useEffect(() => {
        const pathLength = location.pathname
            ?.replace('/', '')
            .split('/')?.length; //Only solution so far since nested routes are not nested based on their parent route name, as such there's no way we can identify iof the route is a dynamic route

        const route = getRoute(location.pathname);


        if (
            route?.roles !== '*' &&
            !route?.roles?.includes(user?.role) &&
            !(pathLength > 1)
        ) {
            navigate('/unauthorized');
            return;
        }
    }, [location.pathname, navigate, user?.role]);

    return (
        <Suspense fallback={<div />}>
            <ScrollToTop />
            <Routes>
                {/* PROPERTY SETTINGS */}
                <Route path={`/category-list`} element={<CategoryList />} />
                <Route path={`/propType-list`} element={<PropertyTypeList />} />
                <Route
                    path={`/amenityCategory-list`}
                    element={<AmenityCategoryList />}
                />
                <Route path={`/amenity-list`} element={<AmenityList />} />
                <Route path={`/community-list`} element={<CommunityList />} />
                <Route path={`/building-list`} element={<BuildingList />} />

                {/* USERS */}
                <Route path={`/user-list`} element={<UsersList />} />
                <Route path={`/landlord-list`} element={<LandLordsList />} />
                <Route path={`/buyers-list`} element={<BuyersList />} />
                <Route path={`/tenants-list`} element={<TenantsList />} />
                <Route path={`/agent-list`} element={<AgentList />} />
                {/* <Route
                    path={`/user-profile/:id/*`}
                    element={<UserProfileLayout />}
                /> */}
                <Route
                    path={`/user-profile/:id/*`}
                    element={<Profile />}
                />

                {/* WAR FRONT */}
                <Route path={`/agent-reports`} element={<AgentReportLayout />} />

                {/* LEADS */}
                <Route path={`/statusBoard`} element={<StatusBoard />}></Route>
                <Route path={`/CreateLead`} element={<CreateLead />}></Route>
                <Route path={`/LeadsUpload`} element={<LeadsUpload />}></Route>
                <Route path={`/LeadsLists`} element={<LeadsLists />}></Route>
                <Route
                    path={`/upload-leads`}
                    element={<NewLeadsUpload />}
                ></Route>
                <Route
                    path={`/lead-sources`}
                    element={<LeadSourcesList />}
                ></Route>

                {/* DND */}
                <Route
                    path={`/dnd-numbers`}
                    element={<DndLeads />}
                ></Route>
                <Route
                    path={`/upload-dnd-numbers`}
                    element={<DndNumbersUploads />}
                ></Route>
                
                {/* EXPLORER */}
                <Route path={`/ImportData`} element={<ImportData />}></Route>
                <Route
                    path={`/list-property-cards`}
                    element={<ListPropertyCards />}
                ></Route>
                <Route
                    path={`/list-checked-out`}
                    element={<ListCheckedOutProperties />}
                ></Route>
                <Route
                    path={`/property-detail/:id`}
                    element={<PropertyCardDetailPage />}
                ></Route>
                <Route
                    path={`/list-pocket-listing`}
                    element={<ListPocketListing />}
                ></Route>
                <Route
                    path={`/add-pocket-listing`}
                    element={<CreatePocketListing />}
                ></Route>
                <Route
                    path={`/list-explorer-leads`}
                    element={<ExplorerLeadsContainer />}
                ></Route>

                {/* COMMUNITY TEAMS */}
                <Route
                    path={`/list-community-teams`}
                    element={<ListCommunityTeams />}
                ></Route>
                <Route
                    path={`/community-team-details/:id`}
                    element={<CommunityTeamDetails />}
                ></Route>

                {/* DEALS */}
                <Route path={`/list-deals`} element={<DealList />}></Route>
                <Route path={`/add-deal`} element={<AddDeal />}></Route>
                <Route path={`/edit-deal/:id`} element={<EditDeal />} />
                <Route path={`/deal-details/:id`} element={<DealDetails />} />

                {/* FINANCES */}
                <Route path={`/deal-transactions`} element={<DealCommissionsContainer />}></Route>
                <Route path={`/finances`} element={<FinancesViewContainer />}></Route>
                <Route path={`/deals-kanban`} element={<DealsKanban />}></Route>

                {/* PROPERTIES */}
                <Route path={`/add-property`} element={<AddProperty />} />
                <Route path={`/edit-property/:id`} element={<EditProperty />} />
                <Route
                    path={`/property-list`}
                    element={<PropertyList />}
                ></Route>
                <Route
                    path={`/property-list/xml-feed`}
                    element={<PropertiesXml />}
                ></Route>
                <Route
                    path={`/property-list/:id`}
                    element={<PropertyDetails />}
                ></Route>
                <Route
                    path={`/property-list/:id/xml`}
                    element={<PropertyDetailsXml />}
                ></Route>

                <Route path={`/profile/:id`} element={<Profile />} />

                {/* AGENCIES */}
                <Route path={`/add-agency`} element={<AddAgency />} />

                {/* DASHBOARDS */}
                {/* <Route path="/agent-dashboard" element={<ProtectedRoutes roles={['admin']}>
          <AgentBoardByAdmin />
        </ProtectedRoutes>} /> */}
                <Route
                    path="/agent-dashboard"
                    element={<AgentBoardByAdmin />}
                />
                <Route
                    path="/marketing-dashboard"
                    element={<MarketingDashboard />}
                />
                <Route
                    path="/listing-performance"
                    element={<ListingPerformance />}
                />
                <Route
                    path="/offplan-marketing-dashboard"
                    element={<OffplanMarketingDashboard />}
                />
                <Route
                    path="/deals-closed-analysis"
                    element={<DealsClosedAnalysis />}
                />
                <Route path="/live-feed" element={<LiveFeed />} />

                {/* Yeastar PBX Dialer */}

                <Route path={`/pbxdialer`} element={<PBXDialer />} />
                <Route path={`/pbxcore`} element={<PBXCore />} />
                <Route
                    path={`/pbx-report-list`}
                    element={<CallReportListContainer />}
                />

                {/* OFFPLANS */}
                <Route path={`/off-plans-list`} element={<OffPlansList />} />
                <Route path={`/add-off-plan`} element={<AddNewDevelopment />} />
                <Route
                    path={`/edit-off-plan/:id`}
                    element={<EditDevelopment />}
                />
                <Route
                    path={`/footer-templates`}
                    element={<FooterTemplates />}
                />
                <Route path={`/all-developers`} element={<AllDevelopers />} />

                {/* BLOGS */}
                <Route path={`/list-blogs`} element={<Blogs />} />

                {/* STAFF */}
                <Route path={`/list-staff`} element={<StaffList />} />

                {/* VACATION */}
                <Route path={`/agent-vacation`} element={<Vacation />} />

                {/* HOME PAGE */}
                <Route path={`/`} element={<MainDashboard />}></Route>

                {/* SETTINGS PAGE */}
                <Route path={`/settings`} element={<AppSettings />}></Route>

                {/* RENDER 404 PAGE IF ROUTE DOES NOT MATCH ANY SPECIFIED ROUTE ABOVE */}
                <Route path="*" element={<Error404Classic />} />

                {/* chang password */}
                <Route path="/change-password" element={<ChangePassword />} />
            </Routes>
        </Suspense>
    );
};
export default Pages;
