import React, { useEffect, useState, useContext } from 'react';
import Head from '../../../layout/head/Head';
import Content from '../../../layout/content/Content';
import DatePicker from 'react-datepicker';
import {
  Block,
  BlockDes,
  BlockHeadContent,
  BlockTitle,
  BlockBetween,
  BlockHead,
  DataTableHead,
  DataTableItem,
  DataTableRow,
  Icon,
  TooltipComponent,
  PaginationComponent,
  PreviewAltCard,
  Row,
  Col,
  RSelect,
  DataTable,
  DataTableBody
} from '../../../components/Component';
import {
  UncontrolledDropdown,
  DropdownMenu,
  DropdownToggle,
  DropdownItem,
  Button,
  Modal,
  ModalBody,
  Badge
} from 'reactstrap';
import { useForm } from 'react-hook-form';
import {
  activeStatus,
  newRoles,
  validatePassword,
  validateEmail
} from '../../../utils/envConfig';
import { toast } from 'react-toastify';
import { endOfDay, format, startOfDay } from 'date-fns';
import { Link, useNavigate } from 'react-router-dom';
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import { isPossiblePhoneNumber } from 'react-phone-number-input';
import { useSelector } from 'react-redux';
import useAxiosPrivate from '../../../hooks/useAxiosPrivate';
import Select from 'react-select';
import { useDispatch } from 'react-redux';
import { fetchUsers } from '../../../redux/actions/user';
import SaveButton from '../../../components/miscellaneous/SaveButton';
import LoadingComponent from '../loader/tableLoader';
import DndFlagComponent from "../dnd/dndFlag";

const TableLayout = ({
  title,
  initialData = [],
  name,
  role = null,
  hide = false
}) => {
  let navigate = useNavigate();
  const axios = useAxiosPrivate();
  // State Variables
  const [editOwner, setEditOwner] = useState(false);
  const [leadSource, setLeadSource] = useState(null);
  const [usersl, setusers] = useState(initialData);
  const [user, setUser] = useState(null);
  const [userId, setuserId] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [totpages, setTotpages] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(50);
  const [resPerPage, setResPerPage] = useState(0);
  const [filCount, setFilCount] = useState(0);
  const [totalCount, settotalCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [saveLoading, setSaveLoading] = useState(false);

  const [search, setSearch] = useState(null);
  const [filterSearch, setFilterSearch] = useState(null);
  const [filterRole, setFilterRole] = useState(role);
  const [filterStatus, setFilterStatus] = useState(null);
  const [filterLeadSource, setFilterLeadSource] = useState(null);
  const [isFiltering, setIsFiltering] = useState(false);
  const [dateValue, setDateValue] = useState({ startDate: '', endDate: '' });

  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [cpassword, setCPassword] = useState('');
  const [errors, setErrors] = useState({});
  const { myerrors, register, handleSubmit } = useForm();
  const [rangeStart, setRangeStart] = useState();
  const [rangeEnd, setRangeEnd] = useState(new Date());
  const [value, setValue] = useState();
  const [errorX, setErrorX] = useState(null);
  const [tablesm, updateTableSm] = useState(false);
  const [onSearch, setonSearch] = useState(true);
  const [sort, setSortState] = useState('DESC');
  const [optLeadSource, setOptLeadSource] = useState(null);
  const [selectedOptLeadSource, setSelectedOptLeadSource] = useState(null);
  const [onSearchText, setSearchText] = useState('');
  const [newUserUpdated, setNewUserAdded] = useState('');
  const [sortName, setSortName] = useState(true);
  const [sortBy, setSorBy] = useState('createdAt');
  const [actionText, setActionText] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const onFilterChange = (e) => {
    setSearchText(e.target.value);
  };
const [checkedAll, setcheckedAll] = useState(false)

const allowedRoles = ["User", "Owner", "Investor"];

  const authuser = useSelector((state) => state?.user?.loggedInUser);

  // localstorage
  const roleGet = localStorage.getItem('userRoleGetValue') || '';
  const statusGet = localStorage.getItem('userStatusGetValue') || '';
  const startDateGet = localStorage.getItem('userStartDateGet') || '';
  const endDateGet = localStorage.getItem('userEndDateGet') || '';
  const filterLeadSourceGet =
    localStorage.getItem('userFilterLeadSourceGetValue') || '';

  const [selectedOptions, setSelectedOptions] = useState([]);

  const CustomMultiValueLabel = ({ data, ...props }) => (
    <div className="selected-option" {...props}>
      {data.label}
    </div>
  );
  const handleSortName = (e) => {
    setSortName(!sortName);
  };
  useEffect(() => {
    setIsLoading(true);
    let link = `/search/user/filter?page=${currentPage}&sort_by=${sortBy}&per_page=${itemsPerPage}&sort_dir=${sort}`;

    if (dateValue.startDate && dateValue.endDate) {
      const fromDate = startOfDay(new Date(dateValue.startDate)).toISOString();
      const toDate = endOfDay(new Date(dateValue.endDate)).toISOString();
      link += `&from_date=${fromDate}&to_date=${toDate}`;
    } else if (startDateGet && endDateGet) {
      const fromDate = startOfDay(new Date(startDateGet)).toISOString();
      const toDate = endOfDay(new Date(endDateGet)).toISOString();
      link += `&from_date=${fromDate}&to_date=${toDate}`;
    } else {
    }

    if (onSearchText)
      link = link.concat(`&search=${encodeURIComponent(onSearchText.trim())}`);

    // Filter only allowed Roles
    if (!roleGet) {
      const filteredRoles = Object.values(newRoles)
        .filter((role) => allowedRoles.includes(role))
        .map((role) => `roles[]=${role}`)
        .join('&');
      link = link.concat(`&${filteredRoles}`);
    }

    const roleValue =
      filterRole || (roleGet !== 'null' && roleGet !== '' && roleGet !== null)
        ? filterRole || roleGet
        : '';
    if (roleValue) {
      link = link.concat(`&roles[]=${roleValue}`);
    }

    const activeValue =
      filterStatus || statusGet
        ? filterStatus === activeStatus[0] || statusGet === activeStatus[0]
        : true;
    link = link.concat(`&active=${activeValue}`);

    let leadSourceQuery =
      filterLeadSource && filterLeadSource[0]?.map(({ label }) => label);
    if (filterLeadSource) {
      link = link.concat(`&lead_source_many=${leadSourceQuery?.join()}`);
    } else if (!filterLeadSource) {
      if (filterLeadSourceGet !== '') {
        const leadSourceArray = JSON.parse(filterLeadSourceGet);
        leadSourceArray?.forEach((lead) => {
          link = link.concat(`&lead_source_many=${lead.label}`);
        });

        setSelectedOptLeadSource(leadSourceArray);
        setSelectedOptions(leadSourceArray);
      }
    }

    dispatch(fetchUsers(axios, link));
  }, [
    dispatch,
    filterSearch,
    filterRole,
    filterStatus,
    filterLeadSource,
    isFiltering,
    dateValue,
    itemsPerPage,
    sort,
    currentPage,
    onSearchText,
    newUserUpdated,
    loading
  ]);
  const usersList = useSelector((state) => state?.user?.users);

  const [users, setUserFilter] = useState([]);

  const userResult = usersList?.findUsersOutput;
  const filteredTotalCount = usersList?.filteredCount;
  const totalDataCount = usersList?.ListingCount;
  const perPageLength = usersList?.searchPerPage;

  const [sortByTitle, setSortByTitle] = useState('');
  const [sortByOrder, setSortByOrder] = useState('');

  useEffect(() => {
    const sortedUsers = [...users];

    sortedUsers.sort((a, b) => {
      const titleA = a[sortByTitle];
      const titleB = b[sortByTitle];
      return sortByOrder === 'asc'
        ? titleA?.localeCompare(titleB)
        : titleB?.localeCompare(titleA);
    });

    setUserFilter(sortedUsers);
  }, [sortByTitle, sortByOrder]);

  useEffect(() => {
    setUserFilter(userResult);
    setFilCount(filteredTotalCount);
    settotalCount(totalDataCount);
    setResPerPage(perPageLength);
    setTotpages(Number(Math.ceil(filteredTotalCount / perPageLength)));
    setIsLoading(false);
    setLoading(true);
  }, [usersList?.findUsersOutput]);

  const bulkActionOptions = [
    { value: 'assign', label: 'Assign to agent' },
    { value: 'status', label: 'Change Status' },
    { value: 'disqualify', label: 'Disqualify' }
  ];

  const onActionText = (e) => {
    setActionText(e.value);
  };

  const onActionClick = (e) => {};

  const handleLeadSource = (e) => {
    setSelectedOptions(e);
    setCurrentPage(1);
    if (e) {
      let lg = [];
      let lgValue = [];
      lg.push(e);
      setSelectedOptLeadSource(lg);
      setFilterLeadSource(lg);
      setIsFiltering(!!e.value);

      const lgString = JSON.stringify(lg[0]);
      localStorage.setItem('leadSourceGetValue', lgString);
      localStorage.setItem('userFilterLeadSourceGetValue', lgString);
    } else {
      if (filterSearch) {
        setIsFiltering(!!filterSearch);
      } else {
        setIsFiltering(false);
      }
      setOptLeadSource({
        label: 'Select ',
        value: null
      });
      setFilterLeadSource(null);
    }
  };

  const handleBadgeRemoveAndLeadSource = (option) => {
    const updatedOptions = selectedOptions.filter(
      (o) => o.value !== option.value
    );
    setSelectedOptions(updatedOptions);
    const updatedSelectedOptLeadSource = selectedOptLeadSource.filter(
      (o) => o.value !== option.value
    );
    setSelectedOptLeadSource(updatedSelectedOptLeadSource);
    setCurrentPage(1);
    if (updatedOptions) {
      let lg = [];
      let lgValue = [];
      lg.push(updatedOptions);
      setSelectedOptLeadSource(lg);
      setFilterLeadSource(lg);
      setIsFiltering(!!updatedOptions.value);

      const lgString = JSON.stringify(lg[0]);
      localStorage.setItem('leadSourceGetValue', lgString);
      localStorage.setItem('userFilterLeadSourceGetValue', lgString);

      // Remove the option from selected OptLeadsource
      const updatedSelOptions = [...selectedOptLeadSource];
      const removedSelOptionIndex = updatedSelOptions.findIndex(
        (options) => options.value === option.value
      );
      if (removedSelOptionIndex !== -1) {
        updatedSelOptions.splice(removedSelOptionIndex, 1);
        setSelectedOptLeadSource(updatedSelOptions);
      }
    } else {
      if (filterSearch) {
        setIsFiltering(!!filterSearch);
      } else {
        setIsFiltering(false);
      }
      setOptLeadSource({
        label: 'Select ',
        value: null
      });
      setFilterLeadSource(null);
    }
  };

  useEffect(() => {
    setLoading(false);
    setLoading(true);
  }, [
    filterSearch,
    filterRole,
    filterStatus,
    filterLeadSource,
    isFiltering,
    dateValue,
    itemsPerPage,
    sort
  ]);

  useEffect(() => {
    async function fetchLeadSource() {
      try {
        const res = await axios.get(`/leadSources`);
        const result = res.data;
        setLeadSource(result);

        let opt = [];
        result &&
          result.map((source) =>
            opt.push({
              value: source.id,
              label: source.name
            })
          );
        setOptLeadSource(opt);
      } catch (err) {}
    }
    fetchLeadSource();
  }, []);

  useEffect(() => {
    if (!itemsPerPage) {
      return;
    }
    setLoading(false);
    setLoading(true);
    setcheckedAll(false)
  }, [currentPage]);

  useEffect(() => {
    setDateValue({
      startDate: rangeStart?.toISOString(),
      endDate: rangeEnd?.toISOString()
    });
  }, [rangeStart, rangeEnd]);

  const handleSearch = async (e) => {
    e.preventDefault();
    if (search !== filterSearch) {
      setusers(null);
      setCurrentPage(1);
      if (search) {
        setFilterSearch(search);
        setIsFiltering(!!search);
      } else {
        if (filterRole) {
          setIsFiltering(!!filterRole);
        } else {
          setIsFiltering(false);
        }
        setFilterSearch(null);
        setSearch(null);
      }
    }
  };

  const handleRoleOption = (e) => {
    setusers(null);
    setCurrentPage(1);
    if (e) {
      setFilterRole(e);
      //setIsFiltering( !!e );
      localStorage.setItem('userRoleGetValue', e);
    } else {
      if (filterSearch) {
        setIsFiltering(!!filterSearch);
      } else {
        setIsFiltering(false);
      }
      localStorage.setItem('userRoleGetValue', '');
      setFilterRole('');
    }
  };

  const handleStatusOption = (status) => {
    // setusers(null);
    setCurrentPage(1);
    if (status == 'Active') {
      setFilterStatus(status);
      setIsFiltering(!filterStatus);
      localStorage.setItem('userStatusGetValue', status);
    } else {
      if (filterSearch) {
        setIsFiltering(!!filterSearch);
      } else {
        setIsFiltering(false);
      }
      setFilterStatus(status);
      localStorage.setItem('userStatusGetValue', status);
    }
  };

  const [editModal, setEditModal] = useState(false);
  const toggleEditModal = () => {
    setEditModal(!editModal);
  };

  const handleEditModal = (e, id) => {
    e.preventDefault();
    setuserId(id);
    fetchOneUser(id);
    toggle('edit');
  };

  const handleRoles = (e) => {
    console.log(e);
    if (e.length > 1) {
      const ownerObject = e.find((item) => item.label === 'Owner');
      if (ownerObject) {
        setEditOwner(true);
      }
    }
    let lg = [];
    e?.forEach((editRole) => lg.push(editRole.label));
    setFormData({ ...formData, allRoles: lg });
  };

  const editMember = async (e) => {
    setSaveLoading(false);
    e.preventDefault();
    if (!validateEdit(formData)) {
      return;
    }

    try {
      setSaveLoading(true);
      const newFormData = {
        ...formData,
        phone: formData?.phone?.trim()?.replaceAll(' ', ''),
        active: formData.active === activeStatus[0],
        leadSourceId: formData?.leadSourceId?.id ?? '',
      };
      const userResponse = await axios.patch(`/users/${userId}`, newFormData);
      const userID = userResponse.data.id;

      const agentID = await axios.get(`/agent/user/${userID}`);

      if (formData.role === newRoles.Agent) {
        if (!agentID.data.id) {
          const formDataNew = {
            userId: userID
          };
          await axios.post(`/agent`, formDataNew);
          toast.success('Created agent for this user');
        }
      }

      toast.success('User modified with changes!');
      onFormCancel();
      setSaveLoading(false);
    } catch (error) {
      setSaveLoading(false);
      toast.error(error?.response?.data?.message , "User not modified with changes!");
    }
  };

  // const [ data, setData ] = useState( orderData );
  const [smOption, setSmOption] = useState(false);
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    phone: '',
    active: activeStatus[0],
    role: '',
    allRoles: []
  });

  async function fetchOneUser(userId) {
    try {
      await axios
        .get(`/users/${userId}`)
        .then((user) => {
          setUser(user.data);
          setFormData({
            ...user?.data,
            firstName: user.data?.first_name,
            lastName: user.data?.last_name,
            email: user.data?.email,
            phone: user.data?.phone,
            active: user.data?.active ? activeStatus[0] : activeStatus[1],
            role: user.data?.role,
            allRoles: user.data?.role_duplicate
          });
        })
        .catch((err) => {
          toast.error('User not available !');
        });
    } catch (err) {
      toast.error('Server error. Try Again !');
    }
  }

  const [view, setView] = useState({
    add: false,
    details: false,
    edit: false
  });

  // toggle function to view order details
  const toggle = (type) => {
    setView({
      add: type === 'add' ? true : false,
      details: type === 'details' ? true : false,
      edit: type === 'edit' ? true : false
    });
  };

  // selects all the order
  const selectorCheck = (e) => {
    setcheckedAll(e.currentTarget?.checked)
    let newData;
    newData = users.map((item) => {
      item.check = e.currentTarget.checked;
      return item;
    });
    setusers([...newData]);
  };

  // selects one order
  const onSelectChange = (e, id) => {
    let newData = users;
    let index = newData.findIndex((item) => item.id === id);
    newData[index].check = e.currentTarget.checked;
    setusers([...newData]);
  };

  // resets forms
  const resetForm = () => {
    setFormData({
      firstName: undefined,
      lastName: undefined,
      email: undefined,
      phone: undefined,
      active: activeStatus[0],
      role: undefined
    });
    setUser(null);
  };

  // Input Validation
  const validate = (firstName, lastName, email, password, cpassword) => {
    let isError = false;
    if (firstName.length < 2) {
      toast.error('Enter valid First Name');
      isError = true;
    }

    if (lastName.length < 2) {
      toast.error('Enter valid Last Name');
      isError = true;
    }

    if (!validateEmail(email)) {
      isError = true;
    }

    if (!value || !value.trim()) {
      isError = true;
      toast.error('Enter valid phone number');

      const isPossiblePhone = isPossiblePhoneNumber(value, 'AE');

      if (isPossiblePhone) {
        isError = true;
        const isValidNum = isValidPhoneNumber(value, 'AE');

        if (isValidNum) {
          isError = false;
        }
      } else {
        isError = true;
        toast.error('Phone number is not possible');
      }
    }

    if (!validatePassword(password, cpassword)) {
      isError = true;
    }
    return isError ? false : true;
  };

  const validateEdit = (formData) => {
    let isError = false;

    if (formData.hasOwnProperty('first_name')) {
      if (!formData.first_name || formData.first_name.length < 2) {
        toast.error('Enter a valid First Name');
        isError = true;
      }
    }

    if (!formData.phone || !formData.phone.trim()) {
      isError = true;
      toast.error('Enter valid phone number');

      const isPossiblePhone = isPossiblePhoneNumber(formData.phone, 'AE');

      if (isPossiblePhone) {
        isError = true;
        const isValidNum = isValidPhoneNumber(formData.phone, 'AE');

        if (isValidNum) {
          isError = false;
        }
      } else {
        isError = true;
        toast.error('Phone number is not possible');
      }
    }

    if (!formData.role) {
      toast.error('Select the Role');
      return false;
    }

    if (isError) {
      toast.error('Invalid fields. Please check your inputs.');
    }

    return isError ? false : true;
  };

  // Add User Info in DB
  const addMember = async (e) => {
    setSaveLoading(false);
    e.preventDefault();
    if (!validate(firstName, lastName, email, password, cpassword)) {
      return;
    }

    if (role === 'Owner') {
      role = newRoles.Owner;
    } else {
      role = newRoles.User;
    }

    const formData = {
        first_name: firstName,
        last_name: lastName,
        email,
        phone: value?.trim()?.replaceAll(' ', ''),
        password,
        role: role
    };

    try {
      setSaveLoading(true);
      const res = await axios
        .post(`/users`, formData)
        .then((user) => {
          if (user?.data?.id) {
            setView({ add: false, details: false, edit: false });
            onFormCancel();
            toast.success('User added successfully!');
            setNewUserAdded(user?.data?.id);
            setSaveLoading(false);
          }
        })
        .catch((err) => {
          setSaveLoading(false);
          // if ((err.response.data.message).includes('duplicate key')) {                // DONT CHANGE MESSAGE, THE SAME REFERED FROM BACKEND
          //     toast.error("User not added. Email already exist !")
          // } else {

          if (err.response && err.response.data && err.response.data.message) {
            toast.error(err.response.data.message);
          } else {
            toast.error('User not added!');
          }
          // }
        });
    } catch (err) {
      toast.error('Server error. Try Again !');
    }
  };

  const handleErrors = (name, err) => {
    setErrors((prev) => ({
      ...prev,
      [name]: err
    }));
  };
  // function to load detail data
  const loadDetail = (id, user) => {
    setUser(user);
    // let index = data.findIndex( ( item ) => item.id === id );
    // setFormData( data[ index ] );
  };
  // function to close the form modal
  const onFormCancel = () => {
    setView({ add: false, details: false, edit: false });
    resetForm();
  };
  // Change Page
  const paginate = (pageNumber) => setCurrentPage(pageNumber);
  const [openAgent, setOpenAgent] = useState(false);
  const toggleModalAgent = () => {
    setOpenAgent(!openAgent);
  };
  const toggleSearch = () => setonSearch(!onSearch);
  const Agents = [
    { value: 'agent 1', label: 'agent 1' },
    { value: 'agent 2', label: 'agent 2' },
    { value: 'agent 3', label: 'agent 3' }
  ];
  const usersAgent = [
    { value: 'user 1', label: 'user 1' },
    { value: 'user 2', label: 'user 2' },
    { value: 'user 3', label: 'user 3' }
  ];

  let clearBadge =
    startDateGet != '' ||
    endDateGet != '' ||
    roleGet != '' ||
    selectedOptions != '';

  return (
    <React.Fragment>
      <Head title={title}></Head>
      <Content>
        <BlockHead size="sm">
          <BlockBetween>
            <BlockHeadContent>
              <BlockTitle tag="h3" page>
                {title}
              </BlockTitle>
              <BlockDes className="text-soft">
                <p>
                  You have total {filCount} {name + "s"}.
                </p>
              </BlockDes>
            </BlockHeadContent>
            <BlockHeadContent>
              <div className="toggle-wrap nk-block-tools-toggle">
                <a
                  href="#more"
                  className="btn btn-icon btn-trigger toggle-expand me-n1"
                  onClick={(ev) => {
                    ev.preventDefault();
                    setSmOption(!smOption);
                  }}
                >
                  <Icon name="more-v"></Icon>
                </a>
                <div
                  className="toggle-expand-content"
                  style={{ display: smOption ? "block" : "none" }}
                >
                  <ul className="nk-block-tools g-3">
                    {/* <li className="nk-block-tools-opt">
                      <Button
                        className="toggle btn-icon d-md-none"
                        color="primary"
                        onClick={ () =>
                        {
                          toggle( "add" );
                        } }
                      >
                        <Icon name="plus"></Icon>
                      </Button>
                      { authuser?.role && (authuser?.role !== newRoles?.Agent) &&
                      <Button
                        className="toggle d-none d-md-inline-flex"
                        color="primary"
                        onClick={ () =>
                        {
                          toggle( "add" );
                        } }
                      >
                        <Icon name="plus"></Icon>
                        <span>Add { name }</span>
                      </Button>
}
                    </li> */}
                  </ul>
                </div>
              </div>
            </BlockHeadContent>
          </BlockBetween>
        </BlockHead>
        <Row>
          <Col sm="12">
            <div className="d-flex justify-content-end align-items-end">
              <ul className="nk-block-tools g-3">
                <li style={{ listStyleType: "none" }}>
                  <UncontrolledDropdown>
                    <DropdownToggle
                      color="transparent"
                      className="dropdown-toggle dropdown-indicator btn btn-outline-light btn-white"
                    >
                      {statusGet
                        ? statusGet
                        : filterStatus
                        ? filterStatus
                        : "Active"}
                    </DropdownToggle>
                    <DropdownMenu start>
                      <ul className="link-list-opt no-bdr">
                        <li>
                          <DropdownItem
                            onClick={() => handleStatusOption("Active")}
                          >
                            <span>Active</span>
                          </DropdownItem>
                        </li>
                        <li>
                          <DropdownItem
                            onClick={() => handleStatusOption("Inactive")}
                          >
                            <span>Inactive</span>
                          </DropdownItem>
                        </li>
                      </ul>
                    </DropdownMenu>
                  </UncontrolledDropdown>
                </li>

                <li>
                  {optLeadSource && (
                    <div className="status-div">
                      <RSelect
                        placeholder="Filter By Leadsource"
                        options={optLeadSource}
                        isMulti
                        value={selectedOptions}
                        onChange={(e) => handleLeadSource(e)}
                        className="status-div-rselect"
                        components={{
                          MultiValueLabel: CustomMultiValueLabel,
                        }}
                      />
                    </div>
                  )}
                </li>

                {!hide &&
                  authuser?.role &&
                  authuser?.role !== newRoles?.Agent && (
                    <li style={{ listStyleType: "none" }}>
                      <UncontrolledDropdown>
                        <DropdownToggle
                          color="transparent"
                          className="dropdown-toggle dropdown-indicator btn btn-outline-light btn-white"
                        >
                          <Icon name="users" />{" "}
                          <span>
                            {roleGet !== "null" &&
                            roleGet !== "" &&
                            roleGet !== null
                              ? roleGet
                              : filterRole
                              ? filterRole
                              : "Select Role"}
                          </span>
                        </DropdownToggle>
                        <DropdownMenu start>
                          <ul className="link-list-opt no-bdr">
                            {roleGet && (
                              <li>
                                <DropdownItem
                                  onClick={(ev) => {
                                    handleRoleOption("");
                                  }}
                                >
                                  <span>All</span>
                                </DropdownItem>
                              </li>
                            )}
                            {Object.keys(newRoles)
                              .filter((role) => allowedRoles.includes(role))
                              .map((role, index) => (
                                <li key={index + role}>
                                  <DropdownItem
                                    onClick={() => handleRoleOption(role)}
                                  >
                                    <span>{newRoles[role]}</span>
                                  </DropdownItem>
                                </li>
                              ))}
                          </ul>
                        </DropdownMenu>
                      </UncontrolledDropdown>
                    </li>
                  )}

                <li>
                  <div className="form-group datepicker-zindex">
                    <div className="form-control-wrap datepicker-zindex">
                      <div className="input-daterange date-picker-range input-group datepicker-zindex">
                        <DatePicker
                          selected={
                            startDateGet ? new Date(startDateGet) : rangeStart
                          }
                          onChange={(date) => {
                            setRangeStart(date);
                            localStorage.setItem(
                              "userStartDateGet",
                              date ? date?.toISOString() : ""
                            );
                            localStorage.setItem(
                              "userEndDateGet",
                              rangeEnd ? rangeEnd.toISOString() : ""
                            );
                          }}
                          selectsStart
                          startDate={
                            startDateGet ? new Date(startDateGet) : rangeStart
                          }
                          endDate={endDateGet ? new Date(endDateGet) : rangeEnd}
                          wrapperClassName="start-m"
                          className="form-control datepicker-zindex"
                        />{" "}
                        <div className="input-group-addon">TO</div>
                        <DatePicker
                          selected={
                            endDateGet ? new Date(endDateGet) : rangeEnd
                          }
                          onChange={(date) => {
                            setRangeEnd(date);
                            localStorage.setItem(
                              "userStartDateGet",
                              rangeStart ? rangeStart.toISOString() : ""
                            );
                            localStorage.setItem(
                              "userEndDateGet",
                              date ? date?.toISOString() : ""
                            );
                          }}
                          startDate={
                            startDateGet ? new Date(startDateGet) : rangeStart
                          }
                          endDate={endDateGet ? new Date(endDateGet) : rangeEnd}
                          selectsEnd
                          minDate={
                            startDateGet ? new Date(startDateGet) : rangeStart
                          }
                          wrapperClassName="end-m"
                          className="form-control datepicker-zindex"
                        />
                      </div>
                    </div>
                  </div>
                </li>
              </ul>{" "}
            </div>
          </Col>
          <Col xs="12" className="mt-2 d-flex justify-content-end pe-4">
            {selectedOptions && (
              <div className="selected-options d-flex flex-wrap">
                {selectedOptions.map((option, index) => (
                  <Badge
                    className="selected-option bg-primary me-1"
                    key={index}
                  >
                    {option.label}{" "}
                    <Icon
                      name="cross-sm"
                      className="ms-1"
                      onClick={() => handleBadgeRemoveAndLeadSource(option)}
                    />
                  </Badge>
                ))}
              </div>
            )}
            {roleGet && (
              <div className="selected-options d-flex flex-wrap">
                <Badge
                  className="selected-option bg-primary me-1"
                  color="primary"
                >
                  {roleGet}
                  <Icon
                    name="cross-sm"
                    className="ms-1"
                    onClick={() => {
                      handleRoleOption("");
                    }}
                  />
                </Badge>
              </div>
            )}

            {clearBadge && (
              <Badge className="selected-option bg-primary me-1" color="danger">
                {"Clear All"}
                <Icon
                  name="cross-sm"
                  className="ms-1"
                  onClick={() => {
                    localStorage.setItem("userRoleGetValue", "");
                    localStorage.setItem("userStartDateGet", "");
                    localStorage.setItem("userEndDateGet", "");
                    localStorage.setItem("userFilterLeadSourceGetValue", "");

                    setSelectedOptions("");
                    setFilterLeadSource("");
                    setRangeStart();
                    setRangeEnd();
                    handleRoleOption("");
                  }}
                />
              </Badge>
            )}
          </Col>
        </Row>
        <Block className="mt-3">
          <DataTable className="card-stretch">
            <div className="card-inner position-relative card-tools-toggle">
              <div className="card-title-group">
                <div className="card-tools">
                  <div className="form-inline flex-nowrap gx-3"></div>
                </div>
                <div className="card-tools me-n1">
                  <ul className="btn-toolbar gx-1">
                    <li>
                      <a
                        href="#search"
                        onClick={(ev) => {
                          ev.preventDefault();
                          toggleSearch();
                        }}
                        className="btn btn-icon search-toggle toggle-search"
                      >
                        <Icon name="search"></Icon>
                      </a>
                    </li>
                    <li className="btn-toolbar-sep"></li>
                    <li>
                      <div className="toggle-wrap">
                        <Button
                          className={`btn-icon btn-trigger toggle ${
                            tablesm ? "active" : ""
                          }`}
                          onClick={() => updateTableSm(true)}
                        >
                          <Icon name="menu-right"></Icon>
                        </Button>
                        <div
                          className={`toggle-content ${
                            tablesm ? "content-active" : ""
                          }`}
                        >
                          <ul className="btn-toolbar gx-1">
                            <li className="toggle-close">
                              <Button
                                className="btn-icon btn-trigger toggle"
                                onClick={() => updateTableSm(false)}
                              >
                                <Icon name="arrow-left"></Icon>
                              </Button>
                            </li>

                            <li>
                              <UncontrolledDropdown>
                                <DropdownToggle
                                  tag="a"
                                  className="btn btn-trigger btn-icon dropdown-toggle"
                                >
                                  <Icon name="setting"></Icon>
                                </DropdownToggle>
                                <DropdownMenu end className="dropdown-menu-xs">
                                  <ul className="link-check">
                                    <li>
                                      <span>Show</span>
                                    </li>
                                    <li
                                      className={
                                        itemsPerPage === 10 ? "active" : ""
                                      }
                                    >
                                      <DropdownItem
                                        tag="a"
                                        href="#dropdownitem"
                                        onClick={(ev) => {
                                          ev.preventDefault();
                                          setCurrentPage(1);
                                          setItemsPerPage(10);
                                        }}
                                      >
                                        10
                                      </DropdownItem>
                                    </li>
                                    <li
                                      className={
                                        itemsPerPage === 15 ? "active" : ""
                                      }
                                    >
                                      <DropdownItem
                                        tag="a"
                                        href="#dropdownitem"
                                        onClick={(ev) => {
                                          ev.preventDefault();
                                          setCurrentPage(1);
                                          setItemsPerPage(15);
                                        }}
                                      >
                                        15
                                      </DropdownItem>
                                    </li>
                                    <li
                                      className={
                                        itemsPerPage === 50 ? "active" : ""
                                      }
                                    >
                                      <DropdownItem
                                        tag="a"
                                        href="#dropdownitem"
                                        onClick={(ev) => {
                                          ev.preventDefault();
                                          setCurrentPage(1);
                                          setItemsPerPage(50);
                                        }}
                                      >
                                        50
                                      </DropdownItem>
                                    </li>
                                    <li
                                      className={
                                        itemsPerPage === 100 ? "active" : ""
                                      }
                                    >
                                      <DropdownItem
                                        tag="a"
                                        href="#dropdownitem"
                                        onClick={(ev) => {
                                          ev.preventDefault();
                                          setCurrentPage(1);
                                          setItemsPerPage(100);
                                        }}
                                      >
                                        100
                                      </DropdownItem>
                                    </li>
                                  </ul>
                                  <ul className="link-check">
                                    <li>
                                      <span>Order</span>
                                    </li>
                                    <li
                                      className={
                                        sort === "DESC" ? "active" : ""
                                      }
                                    >
                                      <DropdownItem
                                        tag="a"
                                        href="#dropdownitem"
                                        onClick={(ev) => {
                                          ev.preventDefault();
                                          setSorBy("createdAt");
                                          setSortState("DESC");
                                          // sortFunc( "dsc" );
                                        }}
                                      >
                                        DESC
                                      </DropdownItem>
                                    </li>
                                    <li
                                      className={sort === "ASC" ? "active" : ""}
                                    >
                                      <DropdownItem
                                        tag="a"
                                        href="#dropdownitem"
                                        onClick={(ev) => {
                                          ev.preventDefault();
                                          setSorBy("createdAt");
                                          setSortState("ASC");
                                          // sortFunc( "asc" );
                                        }}
                                      >
                                        ASC
                                      </DropdownItem>
                                    </li>
                                  </ul>
                                </DropdownMenu>
                              </UncontrolledDropdown>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
              <div
                className={`card-search search-wrap ${!onSearch && "active"}`}
              >
                <div className="card-body">
                  <div className="search-content">
                    <Button
                      className="search-back btn-icon toggle-search active bg-transparent text-secondary"
                      onClick={() => {
                        setSearch("");
                        setFilterSearch("");
                        setSearchText("");
                        toggleSearch();
                      }}
                    >
                      <Icon name="arrow-left"></Icon>
                    </Button>
                    <input
                      type="text"
                      className="border-transparent form-focus-none form-control ps-5  bg-transparent text-secondary"
                      placeholder="Search User by name or email or phone"
                      value={onSearchText}
                      onChange={(e) => onFilterChange(e)}
                    />
                    <Button
                      onClick={(e) => handleSearch(e)}
                      className="search-submit btn-icon  bg-transparent text-secondary border-0"
                    >
                      <Icon name="search"></Icon>
                    </Button>
                  </div>
                </div>
              </div>
            </div>

            <DataTableBody compact>
              <DataTableHead className="nk-tb-item">
                <DataTableRow className="nk-tb-col-check">
                  <div className="custom-control custom-control-sm custom-checkbox notext">
                    <input
                      checked={checkedAll}
                      type="checkbox"
                      className="custom-control-input"
                      id="pid-all"
                      onChange={(e) => {
                        selectorCheck(e);
                      }}
                    />
                    <label
                      className="custom-control-label"
                      htmlFor="pid-all"
                    ></label>
                  </div>
                </DataTableRow>
                <DataTableRow>
                  <div
                    onClick={(ev) => {
                      ev.preventDefault();
                      setSortByTitle("first_name");
                      setSortByOrder(sortByOrder === "asc" ? "desc" : "asc");
                      // sortFunc( "asc" );
                    }}
                    style={{ cursor: "pointer" }}
                  >
                    <span className="sub-text">
                      Name
                      <Icon className="ms-1">
                        <span>&darr;</span>
                        <span>&uarr;</span>
                      </Icon>
                    </span>
                  </div>
                </DataTableRow>
                <DataTableRow size="md">
                  <div
                    onClick={(ev) => {
                      ev.preventDefault();
                      setSortByTitle("phone");
                      setSortByOrder(sortByOrder === "asc" ? "desc" : "asc");
                    }}
                    style={{ cursor: "pointer" }}
                  >
                    <span className="sub-text">
                      Phone{" "}
                      <Icon className="ms-1">
                        <span>&darr;</span>
                        <span>&uarr;</span>
                      </Icon>
                    </span>
                  </div>
                </DataTableRow>
                <DataTableRow size="md">
                  <div
                    onClick={(ev) => {
                      ev.preventDefault();
                      setSortByTitle("lead_source");
                      setSortByOrder(sortByOrder === "asc" ? "desc" : "asc");
                    }}
                    style={{ cursor: "pointer" }}
                  >
                    <span className="sub-text">
                      Lead source{" "}
                      <Icon className="ms-1">
                        <span>&darr;</span>
                        <span>&uarr;</span>
                      </Icon>
                    </span>
                  </div>
                </DataTableRow>
                <DataTableRow>
                  <span className="sub-text">Last Activity Status</span>
                </DataTableRow>
                <DataTableRow size="md">
                  <div
                    onClick={(ev) => {
                      ev.preventDefault();
                      setSortByTitle("created_at");
                      setSortByOrder(sortByOrder === "asc" ? "desc" : "asc");
                      // sortFunc( "asc" );
                    }}
                    style={{ cursor: "pointer" }}
                  >
                    <span className="sub-text">
                      Created{" "}
                      <Icon className="ms-1">
                        <span>&darr;</span>
                        <span>&uarr;</span>
                      </Icon>
                    </span>
                  </div>
                </DataTableRow>
                <DataTableRow>
                  <span className="sub-text">Status</span>
                </DataTableRow>
                <DataTableRow size="md">
                  <div
                    onClick={(ev) => {
                      ev.preventDefault();
                      setSortByTitle("role");
                      setSortByOrder(sortByOrder === "asc" ? "desc" : "asc");
                    }}
                    style={{ cursor: "pointer" }}
                  >
                    <span className="sub-text">
                      Role{" "}
                      <Icon className="ms-1">
                        <span>&darr;</span>
                        <span>&uarr;</span>
                      </Icon>
                    </span>
                  </div>
                </DataTableRow>
                <DataTableRow className="nk-tb-col-tools">
                  <ul className="nk-tb-actions gx-1 my-n1"></ul>
                </DataTableRow>
              </DataTableHead>
              {isLoading ? (
                <LoadingComponent />
              ) : (
                <React.Fragment>
                  {users &&
                    [...users].map((item, index) => (
                      <DataTableItem key={index + "user"}>
                        <DataTableRow className="nk-tb-col-check">
                          <div className="custom-control custom-control-sm custom-checkbox notext">
                            <input
                              type="checkbox"
                              className="custom-control-input"
                              defaultChecked={item.check}
                              id={index + "oId-all"}
                              key={Math.random()}
                              onChange={(e) => onSelectChange(e, item.id)}
                            />
                            <label
                              className="custom-control-label"
                              htmlFor={index + "oId-all"}
                            ></label>
                          </div>
                        </DataTableRow>
                        <DataTableRow>
                          <Link
                            to={`/user-profile/${item?.id}/user-profile-setting`}
                          >
                            {item?.first_name} {item?.last_name}
                          </Link>
                        </DataTableRow>
                        <DataTableRow>
                          {item?.DndStatus === true && item ? (
                            <DndFlagComponent
                              lead_status={item?.lead_status}
                              phoneNumber={item?.phone}
                            />
                          ) : (
                            <span
                              href="#id"
                              onClick={(ev) => ev.preventDefault()}
                            >
                              {item?.phone}
                            </span>
                          )}
                        </DataTableRow>
                        <DataTableRow>
                          <span
                            href="#id"
                            onClick={(ev) => ev.preventDefault()}
                          >
                            {item?.lead_source}
                          </span>
                        </DataTableRow>
                        <DataTableRow>
                          <span
                            href="#id"
                            onClick={(ev) => ev.preventDefault()}
                          >
                            {item?.lead_status}
                          </span>
                        </DataTableRow>
                        <DataTableRow size="md">
                          <span>
                            {item?.created_at &&
                              format(
                                new Date(item?.created_at),
                                "dd/MM/yyyy hh:mm a"
                              )}
                          </span>
                        </DataTableRow>
                        <DataTableRow>
                          <Badge color={item?.active ? "success" : "danger"}>
                            {item?.active ? activeStatus[0] : activeStatus[1]}
                          </Badge>

                          {/* <span
                      className={ `dot bg-${ item.status === "Delivered" ? "success" : "warning" } d-sm-none` }
                    ></span> */}
                          {/* <Badge
                      className="badge-sm badge-dot has-bg d-none d-sm-inline-flex"
                      color={
                        item.status === "Delivered" ? "success" : "warning"
                      }
                    >
                      { item.status }
                    </Badge> */}
                        </DataTableRow>
                        <DataTableRow size="md">
                          <span>{item?.role}</span>
                        </DataTableRow>
                        <DataTableRow className="nk-tb-col-tools">
                          <ul className="nk-tb-actions gx-1">
                            {/* { item.status !== "Delivered" && (
                        <li className="nk-tb-action-hidden" onClick={ () => markAsDelivered( item.id ) }>
                          <TooltipComponent
                            tag="a"
                            containerClassName="btn btn-trigger btn-icon"
                            id={ "delivery" + item.id }
                            icon="truck"
                            direction="top"
                            text="Mark as Delivered"
                          />
                        </li>
                      ) } */}
                            <li
                              className="nk-tb-action-hidden"
                              onClick={() => {
                                loadDetail(item?.id, item);
                                toggle("details");
                              }}
                            >
                              <TooltipComponent
                                tag="a"
                                containerClassName="btn btn-trigger btn-icon"
                                id={"view" + index}
                                icon="eye"
                                direction="top"
                                text="View Details"
                              />
                            </li>
                            <li key={index + item.id}>
                              <UncontrolledDropdown>
                                <DropdownToggle
                                  tag="a"
                                  className="btn btn-icon dropdown-toggle btn-trigger"
                                >
                                  <Icon name="more-h"></Icon>
                                </DropdownToggle>
                                <DropdownMenu end>
                                  <ul className="link-list-opt no-bdr">
                                    <li key={index + Math.random()}>
                                      <a
                                        href={`/user-profile/${item?.id}/user-profile-setting`} // Replace with your desired link
                                      >
                                        <Icon name="eye"></Icon>
                                        <span>User Details</span>
                                      </a>
                                    </li>

                                    {/* { item.status !== "Delivered" && (
                                <li key = { index} >
                                  <DropdownItem
                                    tag="a"
                                    href="#dropdown"
                                    onClick={ ( ev ) =>
                                    {
                                      ev.preventDefault();
                                      markAsDelivered( item.id );
                                    } }
                                  >
                                    <Icon name="truck"></Icon>
                                    <span>Mark as Delivered</span>
                                  </DropdownItem>
                                </li>
                              ) } */}
                                    <li key={index + item?.id}>
                                      <DropdownItem
                                        tag="a"
                                        href="#dropdown"
                                        onClick={(e) => {
                                          handleEditModal(e, item.id);
                                        }}
                                      >
                                        <Icon name="edit"></Icon>
                                        <span>Edit</span>
                                      </DropdownItem>
                                    </li>
                                  </ul>
                                </DropdownMenu>
                              </UncontrolledDropdown>
                            </li>
                          </ul>
                        </DataTableRow>
                      </DataTableItem>
                    ))}
                </React.Fragment>
              )}
            </DataTableBody>
            {/* </div> */}
            {!isLoading && (
              <PreviewAltCard>
                {users?.length > 0 ? (
                  <PaginationComponent
                    itemPerPage={itemsPerPage}
                    totalItems={filCount}
                    paginate={paginate}
                    currentPage={currentPage}
                  />
                ) : (
                  <div className="text-center">
                    <span className="text-silent">
                      No {filterStatus} {name + "s"} Found
                    </span>
                  </div>
                )}
              </PreviewAltCard>
            )}
          </DataTable>
        </Block>
        <Modal isOpen={view.add} className="modal-dialog-centered" size="lg">
          <ModalBody>
            <a href="#cancel" className="close">
              {" "}
              <Icon
                name="cross-sm"
                onClick={(ev) => {
                  ev.preventDefault();
                  onFormCancel();
                }}
              ></Icon>
            </a>
            <div className="p-2">
              <h5 className="title">Add {name}</h5>
              <div className="mt-4">
                {/* <form onSubmit={ handleSubmit( onFormSubmit ) }> */}
                <form onSubmit={(e) => addMember(e)}>
                  <Row className="g-3">
                    <Col md="6">
                      <div className="form-group">
                        <label className="form-label" htmlFor="customer">
                          First Name *
                        </label>
                        <div className="form-control-wrap">
                          <input
                            type="text"
                            className="form-control"
                            name="firstName"
                            value={firstName}
                            onChange={(e) => setFirstName(e.target.value)}
                            ref={register({
                              required: "This field is required",
                            })}
                            defaultValue={""}
                          />
                          {errors.firstName && (
                            <span className="invalid">{errors.firstName}</span>
                          )}
                        </div>
                      </div>
                    </Col>
                    <Col md="6">
                      <div className="form-group">
                        <label className="form-label" htmlFor="customer">
                          Last Name *
                        </label>
                        <div className="form-control-wrap">
                          <input
                            type="text"
                            className="form-control"
                            name="lastName"
                            value={lastName}
                            onChange={(e) => setLastName(e.target.value)}
                            ref={register({
                              required: "This field is required",
                            })}
                            defaultValue={""}
                          />
                          {errors.lastName && (
                            <span className="invalid">{errors.lastName}</span>
                          )}
                        </div>
                      </div>
                    </Col>
                    <Col md="6">
                      <div className="form-group">
                        <label className="form-label" htmlFor="customer">
                          Email *
                        </label>
                        <div className="form-control-wrap">
                          <input
                            type="email"
                            className="form-control"
                            name="email"
                            autoComplete="off"
                            onChange={(e) => setEmail(e.target.value)}
                            ref={register({
                              required: "This field is required",
                            })}
                            defaultValue={""}
                          />
                          {errors.email && (
                            <span className="invalid">{errors.email}</span>
                          )}
                        </div>
                      </div>
                    </Col>

                    <Col md="6">
                      <div className="form-group mb-2">
                        <label className="form-label" htmlFor="first-name">
                          Phone *
                        </label>
                        <PhoneInput
                          className="phoneInput "
                          defaultCountry="AE"
                          value={value}
                          name="phone"
                          placeholder="Enter Your Phone *"
                          onChange={(value) => setValue(value)}
                        ></PhoneInput>
                        <div className="text-black flex">
                          {" "}
                          {errorX && errorX}
                        </div>
                      </div>
                    </Col>
                    <Col md="6">
                      <div className="form-group">
                        <label className="form-label" htmlFor="customer">
                          Password *
                        </label>
                        <div className="form-control-wrap">
                          <input
                            type="password"
                            className="form-control"
                            name="password"
                            autoComplete="new-password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            ref={register({
                              required: "This field is required",
                            })}
                            defaultValue={""}
                          />
                          {errors.password && (
                            <span className="invalid">{errors.password}</span>
                          )}
                        </div>
                      </div>
                    </Col>
                    <Col md="6">
                      <div className="form-group">
                        <label className="form-label" htmlFor="customer">
                          Confirm Password *
                        </label>
                        <div className="form-control-wrap">
                          <input
                            type="password"
                            className="form-control"
                            name="cpassword"
                            value={cpassword}
                            onChange={(e) => setCPassword(e.target.value)}
                            ref={register({
                              required: "This field is required",
                            })}
                            defaultValue={""}
                          />
                          {errors.password && (
                            <span className="invalid">{errors.password}</span>
                          )}
                        </div>
                      </div>
                    </Col>
                    <Col size="12" className="text-end">
                      <Button color="primary" size="lg" disabled={saveLoading}>
                        {saveLoading ? "Adding..." : "Add User"}
                      </Button>
                    </Col>
                  </Row>
                </form>
              </div>
            </div>
          </ModalBody>
        </Modal>

        <Modal
          isOpen={view.details}
          className="modal-dialog-centered"
          size="lg"
        >
          <ModalBody>
            <a href="#cancel" className="close">
              {" "}
              <Icon
                name="cross-sm"
                onClick={(ev) => {
                  ev.preventDefault();
                  onFormCancel();
                }}
              ></Icon>
            </a>
            <div className="nk-tnx-details mt-sm-3">
              <div className="nk-modal-head mb-3">
                <h5 className="title">User Details</h5>
              </div>
              <Row className="gy-3 mb-3">
                <Col lg={6}>
                  <span className="sub-text">First Name</span>
                  <span className="caption-text">{user?.first_name}</span>
                </Col>
                <Col lg={6}>
                  <span className="sub-text">Last Name</span>
                  <span className="caption-text">{user?.last_name}</span>
                </Col>
                <Col lg={6}>
                  <span className="sub-text">Email</span>
                  <span className="caption-text">{user?.email}</span>
                </Col>
                <Col lg={6}>
                  <span className="sub-text">Contact Number</span>
                  <span className="caption-text">{user?.phone}</span>
                </Col>
                <Col lg={6}>
                  <span className="sub-text">Status</span>
                  <span className="caption-text">
                    {user?.active ? activeStatus[0] : activeStatus[1]}
                  </span>
                </Col>
              </Row>
              <div className="nk-modal-head mb-3">
                <h5 className="title">Preferences</h5>
              </div>
              <Row className="gy-3">
                <Col lg={6}>
                  <span className="sub-text">Unit Number</span>
                  <span className="caption-text">
                    {user?.preference?.plotNumber
                      ? user?.preference?.plotNumber
                      : "-"}
                  </span>
                </Col>
                <Col lg={6}>
                  <span className="sub-text">Rooms</span>
                  <span className="caption-text">
                    {user?.preference?.rooms ? user?.preference?.rooms : "-"}
                  </span>
                </Col>
                <Col lg={6}>
                  <span className="sub-text">Type of property</span>
                  <span className="caption-text">
                    {user?.preference?.typeOfProperty
                      ? user?.preference?.typeOfProperty
                      : "-"}
                  </span>
                </Col>
                <Col lg={6}>
                  <span className="sub-text">Building/Area</span>
                  <span className="caption-text">
                    {user?.preference?.area ? user?.preference?.area : "-"}
                  </span>
                </Col>
                <Col lg={6}>
                  <span className="sub-text">Location</span>
                  <span className="caption-text">
                    {user?.preference?.location
                      ? user?.preference?.location
                      : "-"}
                  </span>
                </Col>
              </Row>
              <div className="col-md-6 justify-content-start d-flex">
                <Button
                  outline
                  color="secondary"
                  className="mt-3"
                  onClick={() =>
                    (window.location.href = `/user-profile/${user?.id}/user-profile`)
                  }
                >
                  More Details
                </Button>
              </div>
            </div>
          </ModalBody>
        </Modal>

        <Modal isOpen={view.edit} className="modal-dialog-centered" size="lg">
          <ModalBody>
            <a href="#cancel" className="close">
              <Icon
                name="cross-sm"
                onClick={(ev) => {
                  ev.preventDefault();
                  onFormCancel();
                }}
              ></Icon>
            </a>
            <div className="p-2">
              <h5 className="title">Edit User</h5>
              <div className="mt-4">
                {/* <form onSubmit={ handleSubmit( onFormSubmit ) }> */}
                <form onSubmit={(e) => editMember(e)}>
                  <Row className="g-3">
                    <Col md="6">
                      <div className="form-group">
                        <label className="form-label" htmlFor="customer">
                          First Name
                        </label>
                        <div className="form-control-wrap">
                          <input
                            type="text"
                            className="form-control"
                            name="firstName"
                            defaultValue={formData?.firstName}
                            onChange={(e) =>
                              setFormData({
                                ...formData,
                                first_name: e.target.value,
                              })
                            }
                            ref={register({
                              required: "This field is required",
                            })}
                          />
                          {errors.firstName && (
                            <span className="invalid">{errors.firstName}</span>
                          )}
                        </div>
                      </div>
                    </Col>
                    <Col md="6">
                      <div className="form-group">
                        <label className="form-label" htmlFor="customer">
                          Last Name
                        </label>
                        <div className="form-control-wrap">
                          <input
                            type="text"
                            className="form-control"
                            name="lastName"
                            defaultValue={formData?.lastName}
                            onChange={(e) =>
                              setFormData({
                                ...formData,
                                last_name: e.target.value,
                              })
                            }
                            ref={register({
                              required: "This field is required",
                            })}
                          />
                          {errors.lastName && (
                            <span className="invalid">{errors.lastName}</span>
                          )}
                        </div>
                      </div>
                    </Col>
                    <Col md="6">
                      <div className="form-group">
                        <label className="form-label" htmlFor="customer">
                          Email
                        </label>
                        <div className="form-control-wrap">
                          <input
                            type="email"
                            className="form-control"
                            name="email"
                            defaultValue={formData?.email}
                            onChange={(e) =>
                              setFormData({
                                ...formData,
                                email: e.target.value,
                              })
                            }
                          />
                        </div>
                      </div>
                    </Col>
                    <Col md="6">
                      <div className="form-group mb-2">
                        <label className="form-label" htmlFor="first-name">
                          Phone{" "}
                        </label>
                        <PhoneInput
                          disabled={formData.DndStatus}
                          className="phoneInput"
                          defaultCountry="AE"
                          value={!formData.DndStatus&&formData?.phone}
                          name="phone"
                          placeholder={
                            formData.DndStatus
                              ? "Can't edit DND numbers"
                              : "Enter Your Phone *"
                          }
                          onChange={(value) =>
                            setFormData({ ...formData, phone: value })
                          }
                        />

                        <div className="text-black flex">
                          {" "}
                          {errorX && errorX}
                        </div>
                      </div>
                      {/* <div className="form-group">
                        <label className="form-label" htmlFor="customer">
                          Phone
                        </label>
                        <div className="form-control-wrap">
                          <input
                            type="text"
                            className="form-control"
                            name="phone"
                            defaultValue={formData?.phone}
                            onChange={(e) => setFormData({ ...formData, phone: e.target.value })}
                            ref={register({
                              required: "This field is required",
                            })}

                          />
                          {errors.phone && <span className="invalid">{errors.phone}</span>}
                        </div>
                      </div> */}
                    </Col>
                    {user?.role && user?.role !== newRoles["MasterAdmin"] && (
                      <>
                        <Col md="6">
                          <div className="form-group">
                            <label className="form-label" htmlFor="customer">
                              Status
                            </label>
                            <div className="form-control-wrap">
                              <RSelect
                                defaultValue={{
                                  label: formData.active,
                                  value: formData.active,
                                }}
                                onChange={(e) =>
                                  setFormData({ ...formData, active: e.value })
                                }
                                options={[
                                  {
                                    label: activeStatus[0],
                                    value: activeStatus[0],
                                  },
                                  {
                                    label: activeStatus[1],
                                    value: activeStatus[1],
                                  },
                                ]}
                              />
                            </div>
                          </div>
                        </Col>

                        <Col md="6">
                          <div className="form-group">
                            <label className="form-label" htmlFor="customer">
                              Role
                            </label>
                            <div className="form-control-wrap">
                              <RSelect
                                isMulti
                                options={
                                  newRoles &&
                                  Object.keys(newRoles)
                                    .filter(
                                      (r) => newRoles[r] === newRoles.Owner
                                    )
                                    .map((r) => {
                                      // Options only to add additional roles
                                      // const isDisabled =
                                      //   formData.allRoles?.includes(
                                      //     newRoles[r]
                                      //   ) ||
                                      //   [
                                      //     // newRoles.Owner,
                                      //     newRoles.User,
                                      //     newRoles.MasterAdmin
                                      //   ].includes(newRoles[r]);

                                      // if only 'User', can add only 'Owner' role
                                      const isDisabled =
                                        newRoles[r] !== newRoles.Owner;

                                      return {
                                        label: newRoles[r],
                                        value: newRoles[r],
                                        isDisabled: isDisabled,
                                      };
                                    })
                                }
                                onChange={(e) => handleRoles(e)}
                                defaultValue={
                                  Array.isArray(formData.allRoles) &&
                                  formData.allRoles.map((lang) => ({
                                    label: lang,
                                    value: lang,
                                  }))
                                }
                                className="customInput"
                                isDisabled={
                                  !(
                                    (formData.allRoles?.length === 1 &&
                                      formData.allRoles[0] === newRoles.User) ||
                                    editOwner
                                  )
                                }
                              />
                              {/* <RSelect
                                defaultValue={{
                                  label: formData.role,
                                  value: formData.role
                                }}
                                onChange={(e) =>
                                  setFormData({ ...formData, role: e.value })
                                }
                                options={
                                  newRoles &&
                                  Object.keys(newRoles).map((r) => ({
                                    label: newRoles[r],
                                    value: newRoles[r],
                                    isDisabled:
                                      newRoles[r] !== 'Owner' &&
                                      newRoles[r] !== 'User'
                                  }))
                                }
                                isDisabled={false} // Remove the previous role-based disabling logic
                              /> */}
                            </div>
                          </div>
                        </Col>
                      </>
                    )}
                    <Col size="12" className="text-end">
                      <Button color="primary" size="lg" disabled={saveLoading}>
                        {saveLoading ? "Updating..." : "Edit User"}
                      </Button>
                    </Col>
                  </Row>
                </form>
              </div>
            </div>
          </ModalBody>
        </Modal>
      </Content>
    </React.Fragment>
  );
};

export default TableLayout;
